import { FC, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
  Stack,
  Input,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  NumberInput,
  Text,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Center,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  format,
  parse,
  compareAsc,
  subDays,
  subMonths,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import { useQueryBuilder } from '../context/queryBuilderProvider';

const DateSelector: FC<{}> = ({ ...props }) => {
  const [endDateValid, setEndDateValid] = useState(true);
  const [startDate, setStartDate] = useState(new Date(2020, 0, 1));
  const [endDate, setEndDate] = useState(new Date());

  const { setSelectedDates } = useQueryBuilder();

  useEffect(() => {
    const result = compareAsc(startDate, endDate);
    if (result === 1) {
      setEndDateValid(false);
    }
    if (result <= 0) {
      setEndDateValid(true);
      const dateArray = {
        from: format(startDate, 'yyyy-MM-dd'),
        to: format(endDate, 'yyyy-MM-dd'),
      };
      setSelectedDates(() => dateArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const tryFormatDate = (sd: number | Date) => {
    let sDate = null;
    try {
      sDate = format(sd, 'yyyy-MM-dd');
    } catch (error) {
      const errorDate = format(new Date(2020, 0, 1), 'yyyy-MM-dd');

      console.error('Date error', error, errorDate);

      return errorDate;
    }
    return sDate;
  };

  const updateStartDate = (val: string) => {
    if (val) {
      // console.log('StartDate',val)
      setStartDate(parse(val, 'yyyy-MM-dd', new Date()));
    }
  };

  const updateEndDate = (val: string) => {
    if (val) {
      setEndDate(parse(val, 'yyyy-MM-dd', new Date()));
    }
  };
  const DATE_OPTIONS = {
    YTD: 'YTD',
    MTD: 'MTD',
    Last7days: 'days',
    Last3months: 'months',
  };

  const relativeDates = [
    { key: 'YTD', caption: DATE_OPTIONS.YTD, isFixed: true, defaultVal: 0 },
    { key: 'MTD', caption: DATE_OPTIONS.MTD, isFixed: true, defaultVal: 0 },
    {
      key: 'L7D',
      caption: DATE_OPTIONS.Last7days,
      isFixed: false,
      defaultVal: 7,
    },
    {
      key: 'L3M',
      caption: DATE_OPTIONS.Last3months,
      isFixed: false,
      defaultVal: 3,
    },
  ];
  const [tabIndex, setTabIndex] = useState(0);
  const [value, setValue] = useState(relativeDates.map((e) => e.defaultVal));

  useEffect(() => {
    // console.log('tabIndex changed', relativeDates[tabIndex])
    // console.log(startDate)
    // console.log(endDate)
    switch (relativeDates[tabIndex].caption) {
      case DATE_OPTIONS.YTD: {
        const result = startOfYear(subDays(new Date(), 1));
        setStartDate(() => result);
        const result2 = subDays(new Date(), 1);
        setEndDate(() => result2);
        // console.log("YTD")
        break;
      }
      case DATE_OPTIONS.MTD: {
        const result = startOfMonth(subDays(new Date(), 1));
        setStartDate(() => result);
        const result2 = subDays(new Date(), 1);
        setEndDate(() => result2);
        // console.log("YTD")
        break;
      }
      case DATE_OPTIONS.Last3months: {
        const result = startOfMonth(subMonths(new Date(), value[tabIndex]));
        setStartDate(() => result);

        const result2 = subDays(startOfMonth(new Date()), 1);
        setEndDate(() => result2);
        break;
      }
      case DATE_OPTIONS.Last7days: {
        const result = subDays(new Date(), value[tabIndex] + 1);
        setStartDate(() => result);
        const result2 = subDays(new Date(), 1);
        setEndDate(() => result2);

        break;
      }
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex, value]);

  const handleChange = (val: string, index: number) => {
    console.log('handleChange .... value', value, index);

    const newArr = [...value];
    newArr[index] = parseInt(val, 10);
    console.log('handleChange .... newArr', newArr);
    setValue(newArr);
  };

  // const bg = colors[tabIndex];
  const bg = useColorModeValue('indigo.300', 'indigo.100');
  const color = useColorModeValue('gray.100', 'indigo.800');

  return (
    <Tabs {...props} isFitted variant="enclosed">
      <TabList mb="1em">
        <Tab>Custom</Tab>
        <Tab>Relative</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Stack flex="1" isInline spacing={3}>
            <Input
              value={tryFormatDate(startDate)}
              type="date"
              p={1}
              variant="filled"
              onChange={(e) => updateStartDate(e.target.value)}
            />
            <Input
              isInvalid={!endDateValid}
              errorBorderColor="red.300"
              value={tryFormatDate(endDate)}
              type="date"
              variant="filled"
              p={1}
              min={tryFormatDate(startDate)}
              onChange={(e) => updateEndDate(e.target.value)}
            />
          </Stack>
        </TabPanel>

        <TabPanel>
          <Tabs
            align="center"
            onChange={(index) => setTabIndex(index)}
            variant="unstyled"
            colorScheme="indigo"
          >
            <TabList
              display="flex"
              justifyContent="space-between"
              flex-flow="wrap"
              alignContent="flex-start"
            >
              {/* <HStack spacing={1}> */}
              {relativeDates.map((dt, index) =>
                dt.isFixed ? (
                  <Tab
                    // h="100%"
                    key={dt.key}
                    mx={1}
                    bg="transparent"
                    border="1px"
                    borderColor="indigo.200"
                    boxShadow="lg"
                    rounded="md"
                    _selected={{
                      color,
                      bg,
                      boxShadow: 'sm',
                    }}
                    // bg={tabIndex === index ? 'indigo.800' : 'indigo.100'}
                  >
                    <Center>
                      <Text>{dt.caption}</Text>
                    </Center>
                  </Tab>
                ) : (
                  <Tab
                    mx={1}
                    key={dt.key}
                    bg="transparent"
                    border="1px"
                    borderColor="indigo.200"
                    rounded="md"
                    boxShadow="lg"
                    _selected={{
                      color,
                      bg,
                      boxShadow: 'sm',
                    }}
                  >
                    <InputGroup>
                      <InputLeftAddon
                        // roundedTop="md"
                        border="0px"
                        bg="transparent"
                        // bg={tabIndex === index ? 'indigo.500' : 'indigo.200'}
                        // bg={tabIndex === index ? 'indigo.500' : 'indigo.200'}
                        // eslint-disable-next-line react/no-children-prop
                        children="Last"
                      />
                      <NumberInput
                        // size="sm"
                        min={1}
                        // maxW="75px"
                        // mr="2rem"
                        value={value[index]}
                        onChange={(e) => handleChange(e, index)}
                      >
                        <NumberInputField
                          flex="2"
                          w="60px"
                          justifyContent="flex-start"
                        />
                        <NumberInputStepper flex="1">
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                      <InputRightAddon
                        // rounded="md"
                        border="0px"
                        bg="transparent"
                        // bg={tabIndex === index ? 'indigo.500' : 'indigo.200'}
                        // eslint-disable-next-line react/no-children-prop
                        children={dt.caption}
                      />
                    </InputGroup>
                  </Tab>
                )
              )}
              {/* </HStack> */}
            </TabList>
          </Tabs>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

DateSelector.propTypes = {
  // setSelectedDates: PropTypes.func.isRequired,
};

export default DateSelector;
