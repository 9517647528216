// eslint-disable-next-line import/prefer-default-export
export function RGBAToHexA(r, g, b, a) {
  let rTmp = r.toString(16);
  let gTmp = g.toString(16);
  let bTmp = b.toString(16);
  let aTmp = Math.round(a * 255).toString(16);

  if (rTmp.length === 1) rTmp = '0' + rTmp;
  if (gTmp.length === 1) gTmp = '0' + gTmp;
  if (bTmp.length === 1) bTmp = '0' + bTmp;
  if (aTmp.length === 1) aTmp = '0' + aTmp;

  return '#' + rTmp + gTmp + bTmp + aTmp;
}
