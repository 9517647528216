import { useEffect, useState } from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useGlobal } from '../context/provider';
import { useQueryBuilder } from '../context/queryBuilderProvider';
import DateSelector from './DateSelector';
import ClientSelector from './ClientSelector';

const QueryBuilderMeta = ({ ...props }) => {
  const { loggedIn } = useGlobal();
  const { tables, selectNewTable, selectedTable, clients } = useQueryBuilder();

  const [searchClients] = useState('');
  const [, setFilteredClients] = useState([]);

  const sourceGroups = [...new Set(tables.map((item) => item.group))];

  // const { colorMode } = useColorMode();

  useEffect(() => {
    if (searchClients) {
      const newFiltered = clients.filter((client) =>
        client.client_name.toLowerCase().includes(searchClients.toLowerCase())
      );

      console.log('On Change searchList called.... newFiltered', newFiltered);
      setFilteredClients(newFiltered);
    } else {
      setFilteredClients([]);
    }
  }, [clients, searchClients]);

  const bg = useColorModeValue('indigo.100', 'indigo.800');
  const color = useColorModeValue('gray.800', 'indigo.100');

  return (
    <Stack
      hidden={!loggedIn}
      bg={bg}
      color={color}
      p={2}
      isInline
      justify="space-around"
      spacing={8}
      {...props}
    >
      {/* <Text>{colorMode}</Text> */}

      <Accordion flex="0 0 300px" allowToggle>
        {sourceGroups.map((group) => (
          <AccordionItem key={group}>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                {group}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Stack>
                {tables
                  .filter((t) => t.group === group)
                  .map((f) => (
                    <Stack
                      key={f.id}
                      value={f.id}
                      curser="pointer"
                      onClick={() => selectNewTable(f.id, true)}
                      rounded="md"
                      shadow="sm"
                      bg={
                        f.id === selectedTable.table.id
                          ? 'indigo.200'
                          : 'indigo.50'
                      }
                      color="blue.800"
                      isInline
                      align="center"
                    >
                      <Text fontSize="sm" fontStyle="italic">
                        {`${f.id}: ${f.caption}`}
                      </Text>
                    </Stack>
                  ))}
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>

      <ClientSelector />

      <DateSelector flex="0 0 300px" />
    </Stack>
  );
};

// QueryBuilderMeta.propTypes = {};

export default QueryBuilderMeta;
