/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-props-no-spreading */
import { useRef, useState, useEffect, FC, memo } from 'react';
import {
  useDisclosure,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Text,
  DrawerFooter,
  DrawerCloseButton,
  Stack,
  Box,
  Heading,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Divider,
  Flex,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import { API, graphqlOperation, Storage } from 'aws-amplify';

import { Observable } from 'zen-observable-ts';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { getHistoryList } from '../graphql/queries';
import { subscribeToAddHistory } from '../graphql/subscriptions';

// import { downloadFile } from '../utils/downloadFile';

interface IJsonQuery {
  viewName: string;
  id: string;
  clients: any[];
  query: {
    metrics: any[];
    dimensions: any[];
    filters: any[];
  };
}

const HistoryDrawer: FC<{
  updateFunction: (query: IJsonQuery) => string;
  userName: string;
}> = ({ updateFunction, userName, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const btnRef = useRef<HTMLButtonElement>(null);
  // const [userName, setUserName] = useState({ name: 'Log In' });

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  interface IHistoryItem {
    source: string;
    submitted: boolean;
    query: string;
    id: string;
  }

  const [historyItems, setHistoryItems] = useState<IHistoryItem[]>([]);
  const [jsonQueries, setJsonQueries] = useState<IJsonQuery[]>([]);
  const [nextToken, setNextToken] = useState<string | null>(null);
  const [selectedQuery, setSelectedQuery] = useState<IJsonQuery | null>(null);
  const [selectedHistoryId, setSelectedHistoryId] = useState('');

  // const handleChange = (event) => {
  //   console.log('UserName changed', event.target.value);
  //   setNextToken(null);
  //   setHistoryItems([]);
  //   // setUserName(userList.find((u) => u.name === event.target.value));
  // };

  interface IHistoryList {
    getHistoryList: {
      items: IHistoryItem[];
      nextToken?: string;
    };
  }

  async function getHistory(limit: number) {
    console.log('called get history', nextToken);
    const getData = nextToken
      ? await (API.graphql(
          graphqlOperation(getHistoryList, {
            user_name: userName,
            limit,
            nextToken,
          })
        ) as GraphQLResult<IHistoryList>)
      : await (API.graphql(
          graphqlOperation(getHistoryList, {
            user_name: userName,
            limit,
          })
        ) as GraphQLResult<IHistoryList>);

    const ReceivedData = getData.data;
    if (!ReceivedData) {
      throw new Error('No History data returned');
    }

    console.log('HistoryList fetched', ReceivedData.getHistoryList.items);

    setHistoryItems((p) => [...p, ...ReceivedData.getHistoryList.items]);
    setNextToken(
      ReceivedData.getHistoryList.nextToken
        ? ReceivedData.getHistoryList.nextToken
        : null
    );
  }

  interface ISubscriptionValue {
    value: {
      data: {
        subscribeToAddHistory: any;
        errors: any;
      };
    };
  }

  function subScribeToQueries() {
    console.log('Subscribed to newItems', userName);
    const subscription = (
      API.graphql(
        graphqlOperation(subscribeToAddHistory, { user_name: userName })
      ) as unknown as Observable<object>
    ).subscribe({
      next: (eventData: ISubscriptionValue) => {
        const newLocal = eventData.value.data;
        console.log('Hooray subscribed worked', newLocal);

        if ('errors' in newLocal) {
          console.error('subscribed did not work  ', newLocal.errors);
        } else {
          console.log(newLocal.subscribeToAddHistory);
          // add to the historyItems
          setHistoryItems((p) => [...p, newLocal.subscribeToAddHistory]);
        }
      },
      error: (error) => console.error(error),
    });
    return () => {
      console.log('Unsubscribed to newItems');
      subscription.unsubscribe();
    };
  }

  useEffect(() => {
    console.log('userName changed: ', userName);
    setNextToken(null);
    setHistoryItems([]);
    // updateUser(userName);
    getHistory(3);
    subScribeToQueries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName]);

  async function getSelectedQuery(selectedId: string) {
    console.log(selectedId);
    const item = historyItems.find((e) => e.id === selectedHistoryId);
    console.log(item);
    if (!item) {
      throw new Error('No item selected');
    }

    const [, userId, source] = item.query.split('/');

    console.log('get selectedQuery', userId, source);

    // Get the query from S3

    const link = await Storage.get(source, {
      level: 'protected',
      identityId: 'queries',
    });

    console.log(link);

    fetch(link)
      .then((res) => res.json())
      .then((out) => {
        console.log('Output: ', out);
        const newQuery = { id: selectedId, ...out };
        setJsonQueries((p) => [...p, newQuery]);
        setSelectedQuery(newQuery);
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    if (selectedHistoryId) {
      const xx = jsonQueries.some((e) => e.id === selectedHistoryId);
      console.log(xx);
      if (!xx) {
        setSelectedQuery(null);
        getSelectedQuery(selectedHistoryId);
      } else {
        const foundJsonQuery = jsonQueries.find(
          (e) => e.id === selectedHistoryId
        );
        if (!foundJsonQuery) {
          console.error(
            `No JsonQuery found in with id ${selectedHistoryId}`,
            jsonQueries
          );

          throw new Error('No jsonQuery found ');
        }
        setSelectedQuery(foundJsonQuery);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHistoryId]);

  // eslint-disable-next-line react/display-name
  const Clients: FC<{ itemType: string }> = memo(({ itemType, ...rest }) => {
    const mapLabels = (caption: string, index: number) => (
      <Box
        m={1}
        key={index}
        bg="transparent"
        borderWidth="1px"
        borderColor="indigo.50"
        rounded="sm"
        shadow="sm"
      >
        <Text px={1} color="indigo.900">
          {caption}
        </Text>
      </Box>
    );
    function getSelectedQueryItems() {
      console.log(itemType);
      if (!selectedQuery) {
        throw new Error('No selected query');
      }
      switch (itemType) {
        case 'Clients':
          return selectedQuery.clients.map(({ client: caption }, index) =>
            mapLabels(caption, index)
          );
        case 'Metrics':
          return selectedQuery.query.metrics.map(({ name: caption }, index) =>
            mapLabels(caption, index)
          );
        case 'Dimensions':
          return selectedQuery.query.dimensions.map((p, index) =>
            mapLabels(p, index)
          );
        case 'Filters':
          return selectedQuery.query.filters.map(({ column: caption }, index) =>
            mapLabels(caption, index)
          );

        default:
          console.error(`Cannot find the itemType ${itemType}`);
          return null;
      }
    }

    return (
      <Flex
        flexWrap="wrap"
        justify="flex-end"
        // spacing={1}
        // isInline
        overflowX="auto"
        {...rest}
      >
        {getSelectedQueryItems()}
      </Flex>
    );
  });

  const callUpdateFunction = () => {
    // console.log(updateFunction);
    if (!selectedQuery) {
      console.error('No query selected');

      return;
    }
    updateFunction(selectedQuery);
    onClose();
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const ModalItem: FC<{ header: string }> = ({ header, ...prop }) => (
    <Stack
      isInline
      spacing={3}
      justify="space-between"
      align="center"
      {...prop}
    >
      <Heading size="sm">{header}</Heading>
      <Clients itemType={header} />
    </Stack>
  );

  return (
    <Box {...props}>
      <Stack isInline spacing={1} align="center">
        <Button ref={btnRef} colorScheme="teal" onClick={onOpen}>
          History
        </Button>

        <Text p={3} fontSize="xl" fontStyle="italic" color="indigo.100">
          {userName}
        </Text>
      </Stack>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color="indigo.500">History for {userName}</DrawerHeader>

          <DrawerBody overflowY="auto">
            <Modal isOpen={isModalOpen} onClose={onModalClose} size="xl">
              <ModalOverlay />
              <ModalContent>
                <ModalHeader bg="indigo.500" color="white">
                  {selectedQuery ? selectedQuery.viewName : 'Not found'}
                </ModalHeader>
                <ModalCloseButton color="white" />
                <ModalBody
                  m={4}
                  bg="white"
                  rounded="md"
                  border="1px"
                  borderColor="indigo.50"
                  shadow="lg"
                >
                  <Box>
                    {selectedQuery ? (
                      <Stack>
                        <ModalItem header="Clients" />
                        <Divider />
                        <ModalItem header="Metrics" />
                        <Divider />
                        <ModalItem header="Dimensions" />
                        <Divider />
                        <ModalItem header="Filters" />
                      </Stack>
                    ) : (
                      <Stack isInline>
                        <Spinner
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="indigo.500"
                          size="xl"
                        />
                        <Text>...cannot find query</Text>
                      </Stack>
                    )}
                  </Box>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={onModalClose}>
                    Close
                  </Button>
                  <Button variant="ghost" onClick={callUpdateFunction}>
                    Load query
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
            <Stack spacing={4}>
              {historyItems.map((e) => (
                <Box
                  p={3}
                  shadow={e.id === selectedHistoryId ? 'sm' : 'l'}
                  borderWidth="1px"
                  key={e.id}
                  rounded="md"
                  onClick={() => setSelectedHistoryId(e.id)}
                  bg={e.id === selectedHistoryId ? 'indigo.500' : 'white'}
                  color={e.id !== selectedHistoryId ? 'indigo.500' : 'white'}
                >
                  <Heading fontSize="l">{e.submitted}</Heading>
                  <Stack isInline justify="space-between">
                    <Text mt={4}>{e.source}</Text>
                    <IconButton
                      aria-label="Open history item"
                      icon={<InfoIcon />}
                      onClick={onModalOpen}
                    />
                  </Stack>
                </Box>
              ))}
            </Stack>
          </DrawerBody>

          <DrawerFooter>
            <Button
              isDisabled={!nextToken}
              color="indigo.500"
              mr={3}
              onClick={() => getHistory(2)}
            >
              More items..
            </Button>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              color="blue"
              isDisabled={!selectedQuery}
              onClick={callUpdateFunction}
            >
              Load query
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default HistoryDrawer;
