import { FC, useState } from 'react';

import { Flex, useDisclosure, Button } from '@chakra-ui/react';

import { useDrag, useDrop } from 'react-dnd';
import { EditIcon } from '@chakra-ui/icons';
// import { MdBuild } from 'react-icons/md';
import ModalMetric from './ModalMetric';
import { ItemTypes } from '../utils/items';
import { useQueryBuilder } from '../context/queryBuilderProvider';
import { IDragItem } from '../utils/commonInterfaces';

interface IMetricsItem {
  id: string;
  index: number;
  column: string;
  aggregationMethod: string;
  datatype: string;
  name: string;
}

const MetricsItem: FC<IMetricsItem> = ({
  id,
  index,
  column,
  // findMetric,
  // moveMetric,
  aggregationMethod,
  datatype,
  // updateMetric,
  // setItems,
  name,
  ...props
}) => {
  const { moveCard, findIndexById } = useQueryBuilder();
  const originalIndex = findIndexById(ItemTypes.METRIC, id);
  // const originalIndex = findMetric(id).index;
  const [modalColumn, setModalColumn] = useState('md');
  const [modalAggMethod, setModalAggMethod] = useState('SUM');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleBadgeClick = (col: string, aggMethod: string) => {
    console.log('HandleBadgeClick Column', col);
    console.log('HandleBadgeClick aggMethod', aggMethod);
    setModalColumn(() => col);
    setModalAggMethod(() => aggMethod);
    onOpen();
  };

  // { type: string; id: string; originalIndex: any },
  // void,
  // { isDragging: boolean }

  const [{ isDragging }, drag] = useDrag<
    IDragItem,
    IDragItem,
    { isDragging: boolean }
  >(() => ({
    type: ItemTypes.METRIC,
    item: { type: ItemTypes.METRIC, id, originalIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (_dropResult, monitor) => {
      const { id: droppedId, originalIndex: originalIdx } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        console.log('did not drop', droppedId, originalIdx);
        // moveMetric(droppedId, originalIdx);
        moveCard(droppedId, originalIdx, ItemTypes.METRIC);
      }
    },
  }));
  //
  // const [, drop] = useDrop(
  const [, drop] = useDrop<IDragItem, IDragItem, { isDragging: boolean }>(
    {
      accept: ItemTypes.METRIC,
      canDrop: () => false,
      hover(x: IDragItem) {
        const { id: draggedId } = x;

        if (draggedId !== id) {
          const overIndex = findIndexById(ItemTypes.METRIC, id);

          console.log(x, draggedId, id, overIndex);
          moveCard(draggedId, overIndex, ItemTypes.METRIC);
        }
      },
      // collect(monitor: DropTargetMonitor<IDragItem, IDragItem>) {
      //   console.log(
      //     'canDrop:',
      //     monitor.canDrop(),
      //     'hovered:',
      //     monitor.isOver({ shallow: true }),
      //     'itemType:',
      //     monitor.getItemType()
      //   );
      //   return { isDragging: true };
      // },
    },
    [findIndexById, moveCard]
  );
  const opacity = isDragging ? 0 : 1;

  return (
    <Flex
      ref={(node) => drag(drop(node))}
      mx={1}
      backgroundColor="indigo.50"
      cursor="move"
      justify="space-around"
      opacity={opacity}
      border="1px"
      borderRadius="md"
      rounded="md"
      borderColor="gray.100"
      shadow="lg"
      // flex="1"
      align="space-around"
      key={id}
      {...props}
    >
      <Button
        m={1}
        bg="transparent"
        size="xs"
        // width="100%"
        justifyContent="flex-start"
        leftIcon={<EditIcon />}
        cursor="click"
        onClick={() => handleBadgeClick(column, aggregationMethod)}
      >
        {name + '=' + aggregationMethod + '( ' + column + ' )'}
      </Button>

      <ModalMetric
        // h="0"
        id={id}
        setModalAggMethod={setModalAggMethod}
        modalColumn={modalColumn}
        modalAggMethod={modalAggMethod}
        isOpen={isOpen}
        // updateMetric={updateMetric}
        onClose={onClose}
        datatype={datatype}
      />
    </Flex>
  );
};

export default MetricsItem;
