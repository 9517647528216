// import ReactDOM from 'react-dom';
import './index.css';
import { ChakraProvider } from '@chakra-ui/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { render } from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import customTheme from './theme';
import { GlobalProvider } from './context/provider';
import { QueryBuilderProvider } from './context/queryBuilderProvider';
import { AuthProvider } from './context/authProvider';

const ThemedApp = () => (
  <ChakraProvider theme={customTheme}>
    <DndProvider backend={HTML5Backend}>
      <GlobalProvider>
        <QueryBuilderProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </QueryBuilderProvider>
      </GlobalProvider>
    </DndProvider>
  </ChakraProvider>
);

render(<ThemedApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
