import { FC } from 'react';
import * as PropTypes from 'prop-types';
import { Stack, Heading, useColorModeValue } from '@chakra-ui/react';
import { useDrop } from 'react-dnd';
import { v4 as uuidv4 } from 'uuid';
import { Identifier } from 'dnd-core';
import { ItemTypes } from '../utils/items';
// import { ACTIONS } from '../utils/actions';

import FilterItem from './FilterItem';
// import { move } from '../utils/move';
import { useQueryBuilder } from '../context/queryBuilderProvider';
import { IItem } from '../utils/commonInterfaces';

const FilterBox: FC<{ selectorHeight: number; flex: string }> = ({
  selectorHeight,
  ...props
}) => {
  const { filterItems, addFilter } = useQueryBuilder();

  function addNewFilter(item: IItem, itemType: Identifier) {
    if (itemType === ItemTypes.CARD) {
      addFilter(item, uuidv4());
    }
  }

  const [{ isOver }, drop] = useDrop<IItem, void, { isOver: boolean }>({
    accept: [ItemTypes.CARD, ItemTypes.FILTER],
    drop: (item, monitor) => {
      const itemType = monitor.getItemType();
      if (!itemType) {
        console.error(`No ItemType received`);
        throw new Error(`No ItemType received`);
      }
      addNewFilter(item, itemType);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  // const findFilter = (id) => {
  //   findCard(filterItems, id);
  // };

  const bgIsOver = useColorModeValue('indigo.300', 'indigo.600');

  // const bg = useColorModeValue('indigo.700', 'indigo.100');

  const bgIsNotOver = useColorModeValue('indigo.400', 'indigo.800');
  const color = useColorModeValue('indigo.800', 'indigo.300');

  return (
    <Stack
      shadow="md"
      borderWidth="1px"
      rounded="md"
      overflowY="auto"
      // bg={isOver ? 'indigo.300' : 'indigo.800'}
      bg={isOver ? bgIsOver : bgIsNotOver}
      maxH={selectorHeight}
      ref={drop}
      color={color}
      {...props}
    >
      <Heading size="sm">Filters</Heading>
      <Stack>
        {filterItems.map(({ column, filter, id }, index) => (
          <FilterItem
            key={id}
            column={column}
            filter={filter}
            id={id}
            index={index}
            // findFilter={findFilter}
          />
        ))}
      </Stack>
    </Stack>
  );
};

FilterBox.propTypes = { selectorHeight: PropTypes.number.isRequired };

export default FilterBox;
