/* eslint-disable react/prop-types */
import { fromUnixTime } from 'date-fns';
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';

interface CountryAndAgency {
  agency: string;
  country: string;
}
interface ISelectableValue {
  value: string;
  selected: boolean;
}

interface ICountry extends ISelectableValue {}
interface IAgency extends ISelectableValue {}

export interface ICognitoClient {
  code: string;
  country: string;
  agency: string;
  selected: boolean;
  client_code: string;
  client_name: string;
}
export interface ICognitoUser {
  name: string;
  allUserClients: ICognitoClient[];
  accessToken: string;
  expirationTime: number;
  authTime: string;
}

interface GlobalContextState {
  userName: string;
  loggedIn: boolean;
  accessInfo: {
    accessToken: string;
    expiration: {
      timeStamp: Date;
      unix: number;
    };
    authentication: {
      timeStamp: Date;
      unix: number;
    };
  };
  signIn: (userInput: ICognitoUser) => {
    agency: string;
    country: string;
    userName: any;
    userClients: any;
  };
  countries: ICountry[];
  agencies: IAgency[];
  selectAgency: (c: string) => void;
  selectCountry: (c: string) => void;
  allClients: ICognitoClient[];
}

interface IUserInfo {
  userName: string;
  allClients: ICognitoClient[];
  loggedIn: boolean;
  accessInfo: {
    accessToken: string;
    expiration: {
      timeStamp: Date;
      unix: number;
    };
    authentication: {
      timeStamp: Date;
      unix: number;
    };
  };
}

const GlobalContext = createContext<GlobalContextState>(
  {} as GlobalContextState
);

function alert(arg0: string) {
  throw new Error(`Function not implemented. -- ${arg0}`);
}

export function useGlobal() {
  return useContext(GlobalContext);
}

const notLoggedIn = [{ value: 'NA', selected: true }];

function getAvailable(
  isLoggedIn: boolean,
  getSetMethod: {
    (allC: CountryAndAgency[], queryType: string): {
      value: string;
      selected: boolean;
    }[];
  },
  availableClients: never[],
  queryType: string
) {
  // console.log('getAvailable called', isLoggedIn, availableClients, queryType);
  const available = isLoggedIn
    ? getSetMethod(availableClients, queryType)
    : notLoggedIn;
  // console.log('getAvailable called...available', available);
  return available;
}

function getSet(allC: CountryAndAgency[], queryType: string) {
  function getTheSet(all: CountryAndAgency[], type: string): string[] {
    switch (type) {
      case 'agency': {
        const agencies = all
          .filter((c) => c.agency !== 'ANNA')
          .map((c) => c.agency);

        return agencies;
      }
      case 'country':
        return all.map((c) => c.country);

      default:
        // eslint-disable-next-line no-alert
        alert('unknown type');
        return all.map((c) => c.agency);
    }
  }

  const newAvailable: string[] = [...new Set(getTheSet(allC, queryType))];
  newAvailable.sort();

  const outValues = newAvailable.map((c) => ({
    value: c,
    selected: false,
  }));
  outValues[0].selected = true;
  return outValues;
}

export const GlobalProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const initExpTime = 1893456000;

  const initUser = useMemo(
    () => ({
      userName: 'notset',
      allClients: [],
      loggedIn: false,
      accessInfo: {
        accessToken: 'notset',
        expiration: {
          timeStamp: fromUnixTime(initExpTime),
          unix: initExpTime,
        },
        authentication: {
          timeStamp: fromUnixTime(initExpTime),
          unix: initExpTime,
        },
      },
    }),
    [initExpTime]
  );
  const [userInfo, setUserInfo] = useState<IUserInfo>(initUser);
  const [countries, setCountries] = useState<ICountry[]>(notLoggedIn);
  const [agencies, setAgencies] = useState<IAgency[]>(notLoggedIn);

  useEffect(() => {
    setAgencies(() => getAvailable(false, getSet, [], 'agency'));
    setCountries(() => getAvailable(false, getSet, [], 'country'));
  }, []);

  const { loggedIn, userName, allClients, accessInfo } = userInfo;

  function getFromPrev(prev: ISelectableValue[], c: string) {
    // console.log('GLOBAL...country prev before', prev, c);
    const pMap = prev.map((m) => {
      let selected = false;
      if (m.value === c) {
        selected = true;
      }
      return { ...m, selected };
    });
    // console.log('GLOBAL...country prev after', pMap, c);
    return pMap;
  }

  const signIn = (userInput: {
    name: any;
    allUserClients: any;
    accessToken: any;
    expirationTime: any;
    authTime: any;
  }) => {
    const { name, allUserClients, accessToken, expirationTime, authTime } =
      userInput;
    const ai = {
      accessToken,
      expiration: {
        timeStamp: fromUnixTime(expirationTime),
        unix: expirationTime,
      },
      authentication: { timeStamp: fromUnixTime(authTime), unix: authTime },
    };
    console.log('signIn', ai);

    const newUserInfo = {
      userName: name,
      loggedIn: true,
      allClients: allUserClients,
      accessInfo: ai,
    };
    setUserInfo(() => newUserInfo);

    const availableCountries = getAvailable(
      true,
      getSet,
      allUserClients,
      'country'
    );

    setCountries(() => availableCountries);
    // console.log('SIGN IN ... setAgencies called', allUserClients);

    const availableAgencies = getAvailable(
      true,
      getSet,
      allUserClients,
      'agency'
    );
    // console.log('SIGN IN ... setAgencies called', availableAgencies);
    setAgencies(() => availableAgencies);
    const foundAgency = availableAgencies.find((f) => f.selected);
    const foundCountry = availableCountries.find((f) => f.selected);
    return {
      agency: foundAgency ? foundAgency.value : notLoggedIn[0].value,
      country: foundCountry ? foundCountry.value : notLoggedIn[0].value,
      userName: name,
      userClients: allUserClients,
    };
  };

  const foo = useMemo(() => {
    const selectAgency = (c: string) => {
      setAgencies((prev) => getFromPrev(prev, c));
    };
    const selectCountry = (c: string) => {
      setCountries((prev) => {
        const pMap = getFromPrev(prev, c);
        return pMap;
      });
    };
    const signOut = () => setUserInfo(initUser);
    return {
      userName,
      loggedIn,
      accessInfo,
      signIn,
      signOut,
      allClients,
      agencies,
      countries,
      selectAgency,
      selectCountry,
    };
  }, [
    accessInfo,
    agencies,
    allClients,
    countries,
    initUser,
    loggedIn,
    userName,
  ]);

  return (
    <GlobalContext.Provider value={foo}>{children}</GlobalContext.Provider>
  );
};

// export default useGlobal;
