export const ACTIONS = {
  USER_CHANGED: 'userChanged',
  COUNTRY_OR_AGENCY_CHANGED: 'COUNTRY_OR_AGENCY_CHANGED',
  METRIC_ITEMS_CHANGED: 'METRIC_ITEMS_CHANGED',
  FILTER_ITEMS_CHANGED: 'FILTER_ITEMS_CHANGED',
  DIM_ITEMS_CHANGED: 'DIM_ITEMS_CHANGED',
  SELECTED_CLIENTS_CHANGED: 'SELECTED_CLIENTS_CHANGED',
  SELECTED_TABLE_CHANGED: 'SELECTED_TABLE_CHANGED',
  DATE_CHANGED: 'date_changed',

  // NEW_QUERY: 'new_query',
  //   TOGGLE_METRIC: 'toggle_metric',
  //   TOGGLE_FILTER: 'toggle_filter',
  //   TOGGLE_DIM: 'toggle_dim',
  //   ADD_DIM: 'add_dim',
  //   REMOVE_DIM: 'remove_dim',
  //   MOVE_DIM: 'move_dim',
  //   ADD_FILTER: 'add_filter',
  UPDATE: {
    METRIC: {
      PRETTY_NAME: 'update_metric_pretty',
      AGGREGATION_METHOD: 'update_aggregation_method',
    },
    FILTER: {
      FILTER_TYPE: 'update_filter_filter_type',
      FILTER_VALUE: 'update_filter_value',
    },
  },
  //   INIT: 'init_checked'
};
export default ACTIONS;
