import { FC } from 'react';
import * as PropTypes from 'prop-types';
import { Stack, Heading, useColorModeValue } from '@chakra-ui/react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { Identifier } from 'dnd-core';
import { ItemTypes } from '../utils/items';
import DimensionItem from './DimensionItem';
import { useQueryBuilder } from '../context/queryBuilderProvider';
import { IItem } from '../utils/commonInterfaces';

const DimensionBox: FC<{ selectorHeight: number; flex: string }> = ({
  selectorHeight,
  ...props
}) => {
  const { dimItems, addDimension } = useQueryBuilder();

  function updateCheckDimensions(column: string, itemType: Identifier) {
    console.log('Dimension box... add Card', itemType, column);
    if (itemType === ItemTypes.CARD) {
      // Check if card is not already is added
      if (!dimItems.map((s) => s.dimension).includes(column)) {
        addDimension(column);
      }
    }
  }

  const [{ isOver }, drop] = useDrop({
    accept: [ItemTypes.CARD, ItemTypes.DIM],
    drop: (item: IItem, monitor: DropTargetMonitor<IItem>) => {
      const itemType = monitor.getItemType();
      if (!itemType) {
        console.error(`No ItemType received`);
        throw new Error(`No ItemType received`);
      }
      updateCheckDimensions(item.column, itemType);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const bgIsOver = useColorModeValue('indigo.500', 'indigo.200');
  const bgIsNotOver = useColorModeValue('indigo.200', 'indigo.500');
  const color = useColorModeValue('indigo.600', 'indigo.100');

  return (
    <Stack
      shadow="md"
      borderWidth="1px"
      rounded="md"
      bg={isOver ? bgIsOver : bgIsNotOver}
      overflow="auto"
      spacing={2}
      ref={drop}
      h={selectorHeight}
      {...props}
    >
      <Heading size="sm" color={color}>
        Dimensions
      </Heading>
      <Stack>
        {dimItems.map((dim, index) => (
          <DimensionItem
            key={dim.id}
            dim={dim.dimension}
            index={index}
            id={dim.id}
          />
        ))}
      </Stack>
    </Stack>
  );
};

DimensionBox.propTypes = {
  // items: PropTypes.arrayOf(PropTypes.string).isRequired,
  // setItems: PropTypes.func.isRequired,
  // getColumn: PropTypes.func.isRequired,
  selectorHeight: PropTypes.number.isRequired,
};

export default DimensionBox;
