/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const subscribeToUpdatedStatus = /* GraphQL */ `
  subscription SubscribeToUpdatedStatus($id: String) {
    subscribeToUpdatedStatus(id: $id) {
      id
      status
    }
  }
`;
export const subscribeToUpdatedQueryInfo = /* GraphQL */ `
  subscription SubscribeToUpdatedQueryInfo($id: String) {
    subscribeToUpdatedQueryInfo(id: $id) {
      id
      status
      submitted
      outputlocation
      bytes_scanned
      user_name
      source
      bucket
      query
      error
      message
    }
  }
`;
export const subscribeToAddHistory = /* GraphQL */ `
  subscription SubscribeToAddHistory($user_name: String) {
    subscribeToAddHistory(user_name: $user_name) {
      id
      status
      submitted
      outputlocation
      bytes_scanned
      user_name
      source
      bucket
      query
      error
      message
    }
  }
`;
