import { Flex, Heading, Stack } from '@chakra-ui/react';
import { useDrop } from 'react-dnd';
import { FC } from 'react';
import AthenaColumn from './AthenaColumn';
import { ItemTypes } from '../utils/items';
import { useQueryBuilder } from '../context/queryBuilderProvider';
import { IDragItem } from '../utils/commonInterfaces';
// import { ACTIONS } from '../utils/actions';

const ColumnsBox: FC<{ selectorHeight: number; flex: string }> = ({
  selectorHeight,
  ...props
}) => {
  const { checkedItems, removeDimensions } = useQueryBuilder();

  const [{ isOver }, drop] = useDrop<IDragItem, IDragItem, { isOver: boolean }>(
    {
      accept: [ItemTypes.DIM, ItemTypes.FILTER, ItemTypes.METRIC],
      drop: (item, monitor) => removeDimensions(item.id, monitor.getItemType()),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }
  );

  return (
    <Stack
      shadow="md"
      borderWidth="1px"
      // flex="1"
      rounded="md"
      ref={drop}
      h={selectorHeight}
      bg={isOver ? 'indigo.100' : 'indigo.500'}
      {...props}
    >
      <Heading size="sm" color="indigo.100">
        Columns
      </Heading>
      <Flex
        wrap="wrap"
        overflowY="auto"
        // maxH={selectorHeight}
        align="space-between"
        justify="flex-start"
      >
        {checkedItems.map(({ name }) => (
          <AthenaColumn key={name} name={name} />
        ))}
      </Flex>
    </Stack>
  );
};

export default ColumnsBox;
