/* eslint-disable react/prop-types */
import axios from 'axios';
import { ParseResult } from 'papaparse';
// import Axios from 'axios';
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { usePapaParse } from 'react-papaparse';
import { v4 as uuidv4 } from 'uuid';
import { ACTIONS } from '../utils/actions';
import { IItem } from '../utils/commonInterfaces';

import { ItemTypes } from '../utils/items';
import { move } from '../utils/move';
import { getDescribeTableLink } from '../utils/storageHelpers';
import { useGlobal, ICognitoUser, ICognitoClient } from './provider';

const { REACT_APP_CAMPAIGN_MAPPING_BASE_URL } = process.env;

interface IClient {
  agency: string;
  selected: boolean;
  client_name: string;
  client_code: string;
  country: string;
}

interface IClientInfo {
  clientCode: { client: string; country: string };
  clientName: string;
  agency: string;
}
interface IQueryClient {
  client: string;
  country: string;
}

interface ITable {
  reset?: boolean;
  selected: boolean;
  id: string;
  caption: string;
  source: string;
  view: string;
  group: string;
}

interface ICheckedTable {
  datatype: string;
  name: string;
  checked: boolean;
  dimension: boolean;
  filter: boolean;
  filterList: Set<unknown>;
  metric: {
    include: boolean;
    pretty: string;
    method: string;
  };
  filterInfo: {
    include: boolean;
    filtertype: string;
    datatype: string;
    value: string;
  };
}

interface IView {
  viewName: string;
}

interface IUser {
  agency: string;
  country: string;
}

interface IApiMetric {
  aggregation: string;
  datatype: string;
  column: string;
  name: string;
}

interface IId {
  id: string;
}

interface INewMetric extends IId {
  datatype: string;
  aggregationMethod: string;
  column: string;
  name: string;
  itemType: string;
}

export interface IFilterValue {
  datatype: string;
  filtertype: string;
  value: string;
}

interface INewFilter extends IId {
  datatype: string;
  filter: IFilterValue;
  column: string;
  itemType: string;
}
interface INewDimension extends IId {
  datatype: string;
  dimension: string;
  name: string;
  itemType: string;
}

interface IApiQuery {
  metrics: IApiMetric[];
  dimensions: string[];
  filters: INewFilter[];
}

interface IUpdatedQuery {
  views: IView[];
  clients: IQueryClient[];
  query: IApiQuery;
  user: IUser;
}
interface IQueryBuilderContextState {
  addMetric: (item: { datatype: string; column: string }, id: string) => void;
  queryAlert: {
    showAlert: boolean;
    alertDescription: string;
    alertTitle: string;
  };
  resetQueryAlert: () => void;
  metricItems: INewMetric[];
  checkedItems: ICheckedTable[];
  clients: IClient[];
  dimItems: INewDimension[];
  filterItems: INewFilter[];
  addDimension: any;
  addFilter: (item: IItem, id: string) => void;
  removeDimensions: any;
  findColumn: any;
  GetTableColumnsFromStorage: any;
  getDataBaseByCountry: any;
  updateMetric: any;
  updateFilter: (val: string, id: string, itemMethod: string) => void;
  moveCard: (droppedId: string, originalIdx: number, itemType: string) => void;
  resetItems: any;
  updateQuery: any;
  getQueryInfo: any;
  newQueryInput: any;
  isNotReady: any;
  findIndexById: any;
  tables: any;
  selectedAgency: any;
  selectedCountry: any;
  selectedTable: any;
  selectedClients: any;
  setSelectedDates: any;
  userSignIn: any;
  toggleClient: any;
  UpdateAvailableClients: any;
  selectNewTable: any;
  signInFrom: any;
  signedInFrom: any;
  allowEmptyClientList: any;
  setAllowEmptyClientList: any;
}

const QueryBuilderContext = createContext<IQueryBuilderContextState>(
  {} as IQueryBuilderContextState
);

function alert(arg0: string) {
  throw new Error(arg0);
}

export function useQueryBuilder() {
  return useContext(QueryBuilderContext);
}

const newQueryInput = {
  query: {
    preview: 20,
  },
  views: [
    {
      viewName: 'notset',
      database: 'notset',
    },
  ],
  jobnumber: 'job01',
  clients: [],
  date: {},
  user: {
    name: 'notset',
    agency: 'notset',
    country: 'notset',
  },
};

const tablesXXX = [
  {
    id: '1',
    caption: 'adform',
    source: 'adform.csv',
    view: 'dl_adform_',
    group: 'adform',
    selected: false,
  },
  {
    id: '2',
    caption: 'tracking data',
    source: 'adform_tracking_data.csv',
    view: 'dl_adform_tracking_data_',
    group: 'adform',
    selected: true,
  },
];

export const QueryBuilderProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const {
    countries,
    agencies,
    userName,
    selectAgency,
    selectCountry,
    allClients,
    signIn,
  } = useGlobal();

  const [tables, setTables] = useState(tablesXXX);
  const [clients, setClients] = useState<IClient[]>([]);
  // const [, setDatabase] = useState('data_lake');
  const [metricItems, setMetricItems] = useState<INewMetric[]>([]);
  const [checkedItems, setCheckedItems] = useState<ICheckedTable[]>([]);
  const [dimItems, setDimItems] = useState<INewDimension[]>([]);
  const [filterItems, setFilterItems] = useState<INewFilter[]>([]);
  const [selectedDates, setSelectedDates] = useState();

  const initAlert = useMemo(
    () => ({
      showAlert: false,
      alertDescription: '',
      alertTitle: '',
    }),
    []
  );
  const [queryAlert, setQueryAlert] = useState(initAlert);

  const selectedClients = useMemo(() => {
    const newSelected = clients.filter((a) => a.selected);
    // console.log('selected clients called', newSelected);
    return newSelected;
  }, [clients]);
  // useRef([]);

  const [signInFrom, setSignInFrom] = useState('NOTSET');

  const resetQueryAlert = useCallback(() => {
    setQueryAlert(() => initAlert);
  }, [initAlert]);

  const resetItems = () => {
    setMetricItems([]);
    setDimItems([]);
    setFilterItems([]);
  };

  function convertDataType(dataType: string) {
    const tmpDataType = dataType.toLowerCase().startsWith('varchar')
      ? 'string'
      : dataType.toLowerCase();
    switch (tmpDataType) {
      case 'string':
        return 'STRING';
      case 'date':
        return 'DATE';
      default:
        return 'NUMBER';
    }
  }

  const { readRemoteFile } = usePapaParse();

  const GetTableColumnsFromStorage = useCallback(
    async (table: ITable) => {
      // Get the new table data
      console.log('Calling setTableColumns - async table', table);
      const link = await getDescribeTableLink(table.source);
      // console.log(link);

      type IRemoteDataMap = [string, string];
      interface IColumnDataType {
        column: string;
        dataType: string;
      }

      readRemoteFile(link, {
        download: true,
        header: false,
        complete: (results: ParseResult<IRemoteDataMap>) => {
          const ss: IColumnDataType[] = results.data
            .filter((s) => {
              const [column] = s;
              return column;
            })
            .map((h) => {
              /* [
            { column: "lineItem", dataType: "string" },
            { column: "buyType", dataType: "string" },
          ] */
              /* [
            ["adSet_name", "string"],
            ["adSet_id", "bigint"]
          ] */
              const [column, dataType] = h;
              return { column, dataType };
            });
          console.log('Calling setTableColumns - async', ss);
          // setTableColumns(ss);
          const tableItems = ss.map((col) => {
            const datatype = convertDataType(col.dataType);
            const metricMethod = datatype === 'DATE' ? 'MAX' : 'SUM';
            return {
              datatype,
              name: col.column,
              checked: false,
              dimension: false,
              filter: false,
              filterList: new Set(),
              metric: {
                include: false,
                pretty: col.column,
                method: metricMethod,
              },
              filterInfo: {
                include: false,
                filtertype: 'EQUAL',
                datatype: convertDataType(col.dataType),
                value: '',
              },
            };
          });

          setCheckedItems(() => tableItems);
          console.log('CheckedItems is set', tableItems);

          // reset the metrics
          if (table.reset) {
            setMetricItems([]);
            setDimItems([]);
            setFilterItems([]);
          }
        },
      });
    },
    [readRemoteFile]
  );

  const selectNewTable = useCallback(
    (id: string, resetCols: boolean) => {
      const allTables: ITable[] = tables.map((t) => ({
        ...t,
        selected: t.id === id,
      }));

      const selectedTable = allTables.find((t) => t.selected);
      GetTableColumnsFromStorage(
        selectedTable || {
          reset: false,
          id: 'NA',
          selected: false,
          caption: 'NA',
          source: 'NA',
          view: 'NA',
          group: 'NA',
        }
      );
      setTables(() => allTables);
      console.log('resetCols', resetCols);

      if (resetCols) {
        console.log('resetCols... cols should be reset');
        resetItems();
      }
    },
    [GetTableColumnsFromStorage, tables]
  );

  function UpdateAvailableClients(
    allUserClients: IClient[],
    country: string,
    agency: string
  ) {
    const ss = allUserClients.filter(
      (c) => c.agency === agency && c.country === country
    );

    const unknownClient = {
      client_code: 'UNKNOWN',
      client_name: 'UNKNOWN',
      country,
      agency,
      selected: false,
    };

    ss.push(unknownClient);

    setClients(ss);

    // const newDatabase = getDataBaseByCountry(country);
    // setDatabase(() => newDatabase);

    return ss;
  }

  const updateQuery = useCallback(
    (updatedQuery: IUpdatedQuery) => {
      console.log('Update query called', updatedQuery);
      const {
        views,
        clients: queryClients,
        query,
        user: QueryUser,
      } = updatedQuery;
      const [{ viewName }] = views;

      const { agency, country } = QueryUser;

      const updatedClients = UpdateAvailableClients(
        allClients,
        country,
        agency
      );

      // console.log('Update query called - viewName', viewName);

      // remove "agency" to get the view prefix
      const n = viewName.lastIndexOf('_');
      const res = viewName.substring(0, n + 1);

      const newTable = tables.find((t) => t.view === res);
      if (!newTable) {
        console.error(`No Tables found with view ${res}`, tables);

        throw new Error(`No Tables found with view ${res}`);
      }

      selectNewTable(newTable.id, false);

      const newClients = queryClients.map((cl) => {
        const { client } = cl;
        return client;
      });

      // console.log('Update query called - new clients', newClients);
      // console.log('Update query called - updated clients', updatedClients);

      const ss = updatedClients.map((client) => {
        if (newClients.includes(client.client_code)) {
          return { ...client, selected: true };
        }
        return client;
      });
      // console.log('Update query called - selected clients', ss);

      setClients(() => ss);
      // console.log('Update query called - country and agency', country, agency);
      selectAgency(agency);
      selectCountry(country);

      const { metrics, dimensions, filters } = query;

      const newMetrics = metrics.map((m: IApiMetric) => {
        const { aggregation, ...rest } = m;
        return {
          id: uuidv4(),
          itemType: ItemTypes.METRIC,
          aggregationMethod: aggregation,
          ...rest,
        };
      });
      // console.log('Update query called - newMetrics', newMetrics);
      setMetricItems(() => newMetrics);

      // console.log('Update query called - dimensions', dimensions);

      // console.log('Update query called - dimItems', dimItems);
      const dimValues: INewDimension[] = dimensions.map((d) => ({
        dimension: d,
        id: d,
        datatype: 'STRING',
        name: d,
        itemType: ItemTypes.DIM,
      }));
      // console.log('Update query called - dimensions', dimValues);

      setDimItems(() => dimValues);

      // console.log('Update query called - filters', filters);
      // console.log('Update query called - filterItems', filterItems);
      const newFilters = filters.map((m) => {
        const { ...rest } = m;
        return {
          ...rest,
          id: uuidv4(),
        };
      });
      // console.log('Update query called - newFilters', newFilters);
      setFilterItems(newFilters);
    },
    [allClients, selectAgency, selectCountry, selectNewTable, tables]
  );
  function getDataBaseByCountry(country: string) {
    let newDatabase = 'data_lake';
    switch (country) {
      case 'DK':
        newDatabase = 'data_lake_denmark';
        break;
      case 'SE':
        newDatabase = 'data_lake_sweden';
        break;
      case 'NO':
        newDatabase = 'data_lake_norway';
        break;
      default:
        break;
    }
    return newDatabase;
  }

  const toggleClient = useCallback(
    (clientCode: string, country: string) => {
      const ss = clients.map((client) => {
        if (client.client_code === clientCode && client.country === country) {
          const { selected } = client;
          return { ...client, selected: !selected };
        }
        return client;
      });
      setClients(() => ss);
    },
    [clients]
  );

  const foundCountry = countries.find((s) => s.selected);
  if (!foundCountry) {
    const errorMessage = `No selected country found`;
    console.error(errorMessage, countries);
    throw new Error(errorMessage);
  }
  const selectedCountry = foundCountry.value;

  const foundAgency = agencies.find((s) => s.selected);
  if (!foundAgency) {
    const errorMessage = `No selected agency found`;
    console.error(errorMessage, agencies);
    throw new Error(errorMessage);
  }
  const selectedAgency = foundAgency.value;

  const foundTable = tables.find((t) => t.selected);

  if (!foundTable) {
    const errorMessage = `No selected table found`;
    console.error(errorMessage, tables);
    throw new Error(errorMessage);
  }

  const selectedTable = useMemo(
    () => ({
      table: foundTable,
      reset: true,
    }),
    [foundTable]
  );

  const getQueryInfo = useCallback(
    (previewCount: number) => {
      // Update Clients
      const sc = selectedClients.map((client) => ({
        client: client.client_code,
        country: client.country,
      }));

      // Update Views
      const agency = selectedAgency;
      const country = selectedCountry;
      const agencyLower = agency ? agency.toLowerCase() : 'notset';

      const newViewName = selectedTable.table.view + agencyLower;

      const newView = [
        { viewName: newViewName, database: getDataBaseByCountry(country) },
      ];

      // Update query

      const metrics = metricItems.map((item) => {
        const {
          id,
          aggregationMethod: aggregation,
          datatype,
          ...tempOthers
        } = item;
        const { itemType, ...others } = tempOthers;
        return { aggregation, ...others };
      });

      const filters = filterItems.map((item) => {
        const { id, datatype, itemType, ...others } = item;

        return { ...others };
      });

      const query = {
        preview: previewCount,
        metrics,
        filters,
        dimensions: dimItems.map((d) => d.dimension),
      };

      // Update User
      const user = {
        name: userName,
        country: selectedCountry,
        agency: selectedAgency,
      };

      // Update Dates + User (extract directly from states)

      const newQ = {
        ...newQueryInput,
        query,
        user,
        clients: sc,
        views: newView,
        date: selectedDates,
      };
      console.log('Query: ', newQ);
      return newQ;
    },
    [
      dimItems,
      filterItems,
      metricItems,
      selectedAgency,
      selectedClients,
      selectedCountry,
      selectedDates,
      selectedTable.table.view,
      userName,
    ]
  );

  const removeDim = useCallback(
    (dim: string) => {
      setDimItems(() => {
        // first clone the old previous
        const clone = [...dimItems];
        // then filter
        const aa = clone.filter((t) => t.dimension !== dim);
        // then return
        return aa;
      });
    },
    [dimItems]
  );

  const removeFilter = useCallback(
    (id: string) => {
      setFilterItems(() => {
        // first clone the old previous
        const clone = [...filterItems];
        // then filter
        const aa = clone.filter((t) => t.id !== id);
        // then return
        return aa;
      });
    },
    [filterItems]
  );

  const removeMetric = useCallback(
    (id: string) => {
      setMetricItems(() => {
        // first clone the old previous
        const clone = [...metricItems];
        // then filter
        const aa = clone.filter((t) => t.id !== id);
        // then return
        return aa;
      });
    },
    [metricItems]
  );

  const removeDimensions = useCallback(
    (id: string, itemType: string) => {
      // console.log(id, itemType);
      switch (itemType) {
        case ItemTypes.DIM: {
          removeDim(id);
          break;
        }
        case ItemTypes.FILTER: {
          removeFilter(id);
          break;
        }
        case ItemTypes.METRIC: {
          removeMetric(id);
          break;
        }

        default:
          break;
      }
    },
    [removeDim, removeFilter, removeMetric]
  );

  const addDimension = useCallback((dim: string) => {
    setDimItems((prev) => [
      ...prev,
      {
        dimension: dim,
        id: dim,
        datatype: 'STRING',
        name: dim,
        itemType: ItemTypes.DIM,
      },
    ]);
  }, []);

  const getDefaultValue = (datatype: string) => {
    switch (datatype) {
      case 'STRING':
        return '';
      case 'NUMBER':
        return '0';
      case 'DATE':
        return '2020-01-01';
      default:
        return 'default';
    }
  };

  const replaceVal = (f: INewFilter, val: string) => {
    const { filter, ...item } = f;
    const { value, ...rest } = filter;
    const nf = { ...rest, value: val };
    // console.log('nf', {...item, filter: nf})
    return { ...item, filter: nf };
  };
  const replaceFilterType = (f: INewFilter, val: string) => {
    const { filter, ...item } = f;
    const { filtertype, ...rest } = filter;
    const nf = { ...rest, filtertype: val };
    return { ...item, filter: nf };
  };
  const updateFilter = useCallback(
    (val: string, id: string, itemMethod: string) => {
      setFilterItems((prev) =>
        prev.map((el) =>
          // eslint-disable-next-line no-nested-ternary
          el.id === id
            ? itemMethod === 'value'
              ? replaceVal(el, val)
              : replaceFilterType(el, val)
            : el
        )
      );
    },
    []
  );

  const addFilter = useCallback(
    (item: IItem, id: string) => {
      // console.log(item,id)
      // first make sure id is not present already
      if (!filterItems.some((el) => el.id === id)) {
        const newItem: INewFilter = {
          column: item.column,
          id,
          filter: {
            datatype: item.datatype,
            filtertype: 'EQUAL',
            value: getDefaultValue(item.datatype),
          },
          datatype: item.datatype,
          itemType: ItemTypes.FILTER,
        };
        // console.log('newItem', newItem)

        setFilterItems((prev) => [...prev, newItem]);
      }
    },
    [filterItems]
  );

  const updatePrettyName = useCallback(
    (id: string, val: string) => {
      // console.log('Update Pretty Name', val, id);
      const np = metricItems.map((el) =>
        el.id === id ? { ...el, name: val } : el
      );
      setMetricItems(() => np);
    },
    [metricItems]
  );

  const updateAggMethod = useCallback(
    (id: string, val: string) => {
      // console.log('Update aggMethod', val, id);
      const np = metricItems.map((el) =>
        el.id === id ? { ...el, aggregationMethod: val } : el
      );
      setMetricItems(() => np);
    },
    [metricItems]
  );
  const updateMetric = useCallback(
    (updateType: string, id: string, val: string) => {
      switch (updateType) {
        case ACTIONS.UPDATE.METRIC.AGGREGATION_METHOD:
          updateAggMethod(id, val);
          break;
        case ACTIONS.UPDATE.METRIC.PRETTY_NAME:
          updatePrettyName(id, val);
          break;
        default:
          console.log('Unknown Metric Update');
          break;
      }
    },
    [updateAggMethod, updatePrettyName]
  );

  const findCard = (items: IId[], id: string) => {
    const card = items.find((c) => c.id === id);
    if (!card) {
      const errorMessage = `No item found with ${id}`;
      console.error(errorMessage, items);
      throw new Error(errorMessage);
    }

    return {
      card,
      index: items.indexOf(card),
    };
  };

  const findIndexById = useCallback(
    (indexType: string, id: string) => {
      switch (indexType) {
        case ItemTypes.DIM: {
          const { index } = findCard(dimItems, id);
          return index;
        }
        case ItemTypes.FILTER: {
          const { index } = findCard(filterItems, id);
          return index;
        }
        case ItemTypes.METRIC: {
          const { index } = findCard(metricItems, id);
          return index;
        }

        default:
          alert('Unknown index type');
          return null;
      }
    },
    [dimItems, filterItems, metricItems]
  );

  const moveCard = useCallback(
    (id: string, atIndex: number, indexType: string) => {
      switch (indexType) {
        case ItemTypes.DIM:
          {
            const { index } = findCard(dimItems, id);
            const cloneItems = [...dimItems];
            setDimItems(() => move(cloneItems, index, atIndex));
          }
          break;
        case ItemTypes.FILTER:
          {
            const { index } = findCard(filterItems, id);
            // console.log('QueryBuilderProvider.....', id, index);

            const cloneItems = [...filterItems];
            // console.log('QueryBuilderProvider.....', cloneItems, index, atIndex);
            setFilterItems(() => move(cloneItems, index, atIndex));
          }
          break;
        case ItemTypes.METRIC:
          {
            const { index } = findCard(metricItems, id);
            const cloneItems = [...metricItems];

            setMetricItems(() => move(cloneItems, index, atIndex));
          }
          break;

        default:
          // eslint-disable-next-line no-alert
          alert('Unknown index type');
      }
    },
    [dimItems, filterItems, metricItems]
  );

  const resetClients = useCallback(
    (cognitoUser: ICognitoUser): ICognitoClient[] => {
      const campaignMapping = axios.create({
        baseURL: REACT_APP_CAMPAIGN_MAPPING_BASE_URL,
        timeout: 10000,
        headers: { Authorization: `Bearer ${cognitoUser.accessToken}` },
      });
      let allUserClients: ICognitoClient[] = [];

      campaignMapping.get('clients').then((x) => {
        const newClients = x.data.clients;
        allUserClients = newClients.map((c: IClientInfo) => ({
          client_code: c.clientCode.client,
          client_name: c.clientName,
          country: c.clientCode.country,
          agency: c.agency,
          selected: false,
        }));
        const userWithAgencyAndCountry: ICognitoUser = {
          ...cognitoUser,
          allUserClients,
        };

        const ss = signIn(userWithAgencyAndCountry);

        const { country, agency, userClients } = ss;

        UpdateAvailableClients(userClients, country, agency);
      });
      return allUserClients;
    },
    [signIn]
  );

  const userSignIn = useCallback(
    (newUser: ICognitoUser) => {
      // Create user object from amplify Cognito userData so we only have to navigate it once
      resetItems();
      resetClients(newUser);
    },
    [resetClients]
  );

  function signedInFrom() {
    console.log('Hub...set signInFrom');
    setSignInFrom(() => '/TV');
  }

  useEffect(() => {
    async function GetTables(source: string) {
      const link = await getDescribeTableLink(source);
      console.log(link);

      readRemoteFile(link, {
        download: true,
        header: true,
        complete: (results: ParseResult<ITable>) => {
          const allTables = results.data.map((t) => ({
            ...t,
            selected: t.id === '1',
          }));

          const selectTable = allTables.find((t) => t.selected);

          if (!selectTable) {
            console.error(`No selected table found`, tables);

            throw new Error(`No selected table found`);
          }

          GetTableColumnsFromStorage(selectTable);

          setTables(allTables);
        },
      });
    }
    const go = async () => {
      // Get the new table data
      await GetTables('tables.csv');
    };
    go();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   console.log('selected clients called', selectedClients.current);
  //   selectedClients.current = clients.filter((a) => a.selected);
  // }, [clients]);

  const [allowEmptyClientList, setAllowEmptyClientList] = useState(false);

  const isNotReady =
    // selectedClients.current.length === 0 || metricItems.length === 0;

    (selectedClients.length === 0 && allowEmptyClientList !== true) ||
    metricItems.length === 0;

  const findColumn = useCallback(
    (column: string) => {
      const outValue = checkedItems.find((s) => s.name === column);
      if (!outValue) {
        const errorMessage = `No checked item found where name = ${column}`;
        console.error(errorMessage, checkedItems);
        throw new Error(errorMessage);
      }
      // console.log('call in findColumn', column, checkedItems, outValue);
      return outValue;
    },
    [checkedItems]
  );

  const addMetric = useCallback(
    (item: { datatype: string; column: string }, id: string) => {
      console.log('add metric', item, id, metricItems);
      const { datatype, column } = item;
      console.log('dataType', datatype);
      if (datatype === 'STRING') {
        const newAlertDescription =
          'It is not possible to add a STRING variable as metric. Use Dimensions instead if you want to group by "' +
          column.toUpperCase() +
          '"';
        const newAlertTitle =
          'Cannot use "' + column.toUpperCase() + '" as a metric';
        // setAlertDescription(newAlertDescription);

        // setAlertTitle(newAlertTitle);
        // setShowAlert(() => true);
        setQueryAlert(() => ({
          showAlert: true,
          alertDescription: newAlertDescription,
          alertTitle: newAlertTitle,
        }));

        return;
      }
      // first make sure id is not present already
      if (!metricItems.some((el) => el.id === id)) {
        console.log('column:', column, id, metricItems);
        const { metric } = findColumn(column);
        // console.log(metric)
        const newItem: INewMetric = {
          id,
          column,
          aggregationMethod: metric.method,
          datatype,
          name: metric.pretty,
          itemType: ItemTypes.METRIC,
        };
        console.log('newItem', newItem);
        setMetricItems((prev) => [...prev, newItem]);
      }
    },
    [findColumn, metricItems]
  );

  const foo = useMemo(
    () => ({
      clients,
      checkedItems,
      metricItems,
      dimItems,
      filterItems,
      addMetric,
      addDimension,
      addFilter,
      removeDimensions,
      findColumn,
      queryAlert,
      resetQueryAlert,
      GetTableColumnsFromStorage,
      getDataBaseByCountry,
      updateMetric,
      updateFilter,
      moveCard,
      resetItems,
      updateQuery,
      getQueryInfo,
      newQueryInput,
      isNotReady,
      findIndexById,
      tables,
      selectedAgency,
      selectedCountry,
      selectedTable,
      selectedClients,
      setSelectedDates,
      userSignIn,
      toggleClient,
      UpdateAvailableClients,
      selectNewTable,
      signInFrom,
      signedInFrom,
      allowEmptyClientList,
      setAllowEmptyClientList,
    }),
    [
      GetTableColumnsFromStorage,
      addDimension,
      addFilter,
      addMetric,
      allowEmptyClientList,
      checkedItems,
      clients,
      dimItems,
      filterItems,
      findColumn,
      findIndexById,
      getQueryInfo,
      isNotReady,
      metricItems,
      moveCard,
      queryAlert,
      removeDimensions,
      resetQueryAlert,
      selectNewTable,
      selectedAgency,
      selectedClients,
      selectedCountry,
      selectedTable,
      signInFrom,
      tables,
      toggleClient,
      updateFilter,
      updateMetric,
      updateQuery,
      userSignIn,
    ]
  );

  return (
    <QueryBuilderContext.Provider value={foo}>
      {children}
    </QueryBuilderContext.Provider>
  );
};

// export default useGlobal;
