import { FC, SetStateAction, useEffect, useState } from 'react';
import { Observable } from 'zen-observable-ts';
import {
  Button,
  Flex,
  IconButton,
  Modal,
  Spinner,
  Stack,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  VStack,
  Switch,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { MdSettings } from 'react-icons/md';
import { API, graphqlOperation, Storage } from 'aws-amplify';

import { createWriteStream } from 'streamsaver';
import { usePapaParse } from 'react-papaparse';
import { ParseResult } from 'papaparse';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { useGlobal } from '../context/provider';
import { DOWNLOAD_TYPE } from '../utils/downloadType';
import OutputView from './OutputView';
import { byteConverter, currencyToDKK, pricePrTera } from '../utils/constants';

import { mcdColumns, mcdData } from '../data/sampledata';
import { startQuery } from '../graphql/mutations';
import {
  subscribeToUpdatedQueryInfo,
  subscribeToUpdatedStatus,
} from '../graphql/subscriptions';
import { useQueryBuilder } from '../context/queryBuilderProvider';

const ResultsComponent: FC<{
  alertSignedOut: () => void;
  alertMessage: (message: string, title: string, callSignOut: boolean) => void;
}> = ({ alertSignedOut, alertMessage, ...props }) => {
  const { loggedIn } = useGlobal();
  const {
    dimItems,
    metricItems,
    isNotReady,
    getQueryInfo,
    allowEmptyClientList,
    setAllowEmptyClientList,
  } = useQueryBuilder();
  const [queryInfo, setQueryInfo] = useState({
    outputLocation: 'not set',
    bytesScanned: 0,
  });

  const { readRemoteFile } = usePapaParse();

  const [preview, setPreview] = useState(20);
  const [downloadType, setDownloadType] = useState(DOWNLOAD_TYPE.INIT);

  interface IResultData {
    [index: string]: string;
  }
  interface IResultColumns {
    Header: string;
    accessor: string;
    isDim: boolean;
  }

  const [data, setData] = useState<IResultData[]>(mcdData);
  const [columns, setColumns] = useState<IResultColumns[]>(mcdColumns);
  const [queryId, setQueryId] = useState('dddd');
  const [subscribeStatus, setSubscribeStatus] = useState('init');

  function previewFile(link: string) {
    readRemoteFile(link, {
      download: true,
      header: true,
      complete: (results: ParseResult<IResultData>) => {
        console.log(results);
        setData(results.data);
        const resultFields = results.meta.fields;
        if (!resultFields) {
          console.error(`No Meta results found`, results.meta);

          throw new Error(`No Meta results found`);
        }
        setColumns(
          resultFields.map((h: string) =>
            // const isDim = dimItems.includes(h)

            ({
              Header: h,
              accessor: h,
              isDim: dimItems.map((d) => d.dimension).includes(h),
            })
          )
        );
      },
      error: (error) => {
        console.log('error============:', error);
        alertSignedOut();
        // Auth.signOut();
        // signOut();
      },
    });
  }

  const downloadFile = (url: RequestInfo, fileName: string) =>
    fetch(url)
      .then((res) => {
        // **** This link explains the MITM approach by streamsaver https://www.gitmemory.com/issue/jimmywarting/StreamSaver.js/99/491826027
        // **** https://github.com/jimmywarting/StreamSaver.js/wiki/How-it-works

        const fileStream = createWriteStream(fileName);

        const readableStream = res.body;
        if (readableStream) {
          if (window.WritableStream && readableStream.pipeTo) {
            return readableStream
              .pipeTo(fileStream)
              .then(() => console.log('done writing'));
          }
          const writer = fileStream.getWriter();

          const reader = readableStream.getReader();
          // eslint-disable-next-line no-inner-declarations
          function pump(): void {
            reader
              .read()
              .then((_res) =>
                _res.done
                  ? writer.close()
                  : writer
                      .write(_res.value)
                      .then(pump)
                      .catch(() => {
                        throw new Error('Cannot pump');
                      })
                      .finally(() => console.log('Catch finally'))
              )
              .catch(() => {
                throw new Error('Cannot read');
              })
              .finally(() => console.log('Finally catches'));
          }

          pump();
        }
        console.error('No readable stream');
        throw new Error(`No readable stream`);

        // const writer = fileStream.getWriter();
        // if (res.body.pipeTo) {
        //   writer.releaseLock();
        //   return res.body.pipeTo(fileStream);
        // }

        // const reader = res.body.getReader();
        // const pump = () =>
        //   reader
        //     .read()
        //     .then(({ value, done }) =>
        //       done ? writer.close() : writer.write(value).then(pump)
        //     );

        // return pump();
      })
      .catch((error) => {
        console.log('error============:', error);
        alertSignedOut();
      });
  async function getDownloadLink(qInfo: {
    outputLocation: any;
    bytesScanned?: number;
  }) {
    console.log('Query_file:', qInfo.outputLocation);
    console.log(qInfo.outputLocation.split('/'));
    const [, , bucket, level, ...keys] = qInfo.outputLocation.split('/');
    console.log('level', level);
    console.log('bucket', bucket);
    const keyJoin = keys.join('/');
    console.log(keyJoin);
    const [outputPre, workGroup, keyId] = keyJoin.split('/');
    const link = await Storage.get(keyId, {
      level: 'protected',
      identityId: [outputPre, workGroup].join('/'),
    });
    console.log('link', link);
    // console.log("keyId", keyId);
    return link;
  }

  useEffect(() => {
    const go = async () => {
      switch (downloadType) {
        case DOWNLOAD_TYPE.DOWNLOAD: {
          console.log('.....download started for ', queryInfo);
          const link = await getDownloadLink(queryInfo);
          downloadFile(link, `${queryId}.csv`).then(() => {
            console.log('done downloading');
          });
          break;
        }
        case DOWNLOAD_TYPE.PREVIEW: {
          console.log('.....preview started for ', queryInfo);
          const link = await getDownloadLink(queryInfo);
          previewFile(link);
          break;
        }
        default:
          break;
      }
    };
    go();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryInfo]);

  async function newQuery(previewCount: number, type: SetStateAction<string>) {
    // First validate the query
    // 1) all metrics and dimensions should have different names
    function checkForDuplicates(array: Array<string>) {
      if (!array) {
        console.log('No array found', array);
        throw new Error('No array found');
      }
      return new Set(array).size !== array.length;
    }

    console.log(
      'ResultsComponent....newQuery',
      loggedIn,
      metricItems,
      dimItems
    );

    const columnArray = metricItems
      .map((a) => a.name.toLowerCase())
      .concat(dimItems.map((a) => a.dimension.toLowerCase()));

    console.log('ResultsComponent... New ResultsComponent', columnArray);
    console.log(
      'ResultsComponent...New query, checkDuplicates',
      checkForDuplicates(columnArray)
    );

    if (checkForDuplicates(columnArray)) {
      console.log('Duplicate found - should show alert');
      alertMessage(
        'The query is not unambiguous. There are metrics or dimensions that have the same name',
        'Ambiguous names in query ',
        false
      );
      return;
    }

    setDownloadType(type);

    if (type !== DOWNLOAD_TYPE.INIT) {
      const q1 = getQueryInfo(previewCount);
      try {
        const queryData = (await API.graphql(
          graphqlOperation(startQuery, { query: q1 })
        )) as GraphQLResult<{ startQuery: { id: string } }>;
        console.log('query started', queryData);
        if (!queryData.data) {
          throw new Error('No Query data returned');
        }
        const qid = queryData.data.startQuery.id;
        console.log('QID =', qid);
        setQueryId(qid);
        console.log({ queryId });
      } catch (err) {
        console.log('error starting...', err);
      }
    }
  }

  useEffect(() => {
    console.log('Subscribed', queryId);
    interface ISubscriptionValue {
      value: {
        data: {
          subscribeToUpdatedStatus: {
            status: SetStateAction<string>;
          };
        };
      };
    }

    // const subscription = API.graphql<GraphQLSubscription<typeof onCreateTodo>>(
    //   graphqlOperation(onCreateTodo)
    // ).subscribe({...});

    const subscription = (
      API.graphql(
        graphqlOperation(subscribeToUpdatedStatus, {
          id: queryId,
        })
      ) as unknown as Observable<object>
    ).subscribe({
      next: (eventData: ISubscriptionValue) => {
        console.log(
          'Subscribe status 0',
          eventData.value.data.subscribeToUpdatedStatus.status
        );
        setSubscribeStatus(
          eventData.value.data.subscribeToUpdatedStatus.status
        );
      },
      error: (error: any) => console.error(error),
    });
    return () => {
      console.log('Unsubscribed');
      subscription.unsubscribe();
    };
  }, [queryId]);

  useEffect(() => {
    console.log('Subscribed to queryInfo', queryId);
    const subscription = (
      API.graphql(
        graphqlOperation(subscribeToUpdatedQueryInfo, { id: queryId })
      ) as unknown as Observable<object>
    ).subscribe({
      next: (eventData: {
        value: { data: { subscribeToUpdatedQueryInfo: any } };
      }) => {
        console.log(eventData);
        console.log(eventData.value.data.subscribeToUpdatedQueryInfo);
        const qInfo = eventData.value.data.subscribeToUpdatedQueryInfo;
        const setQ = {
          outputLocation: qInfo.outputlocation,
          bytesScanned: qInfo.bytes_scanned,
          dkk:
            (qInfo.bytes_scanned / byteConverter) * currencyToDKK * pricePrTera,
        };
        setQueryInfo(setQ);
        // setDkk((s) => s + setQ.dkk);
      },
      error: (error: any) => console.error(error),
    });
    return () => {
      console.log('Unsubscribed to queryInfo');
      subscription.unsubscribe();
    };
  }, [queryId]);

  //   console.log('ResultsComponent....loggedIn', loggedIn, metricItems, dimItems);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleChange = (p: string | number) => {
    const pInt = typeof p === 'string' ? parseInt(p, 10) : p;

    setPreview(pInt);
  };
  return (
    <Stack {...props}>
      <Stack
        hidden={!loggedIn}
        p={2}
        isInline
        justify="flex-end"
        align="center"
      >
        <Button
          isDisabled={isNotReady}
          onClick={() => newQuery(preview, DOWNLOAD_TYPE.PREVIEW)}
          colorScheme="indigo"
        >
          Preview
        </Button>

        <Button
          isDisabled={isNotReady}
          onClick={() => newQuery(-99, DOWNLOAD_TYPE.DOWNLOAD)}
        >
          Download
        </Button>
        <Button isDisabled>Report</Button>
        <IconButton
          aria-label="Settings"
          icon={<MdSettings />}
          onClick={onOpen}
        />
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Set number of rows in preview</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack>
                <NumberInput
                  step={10}
                  min={1}
                  max={1000}
                  value={preview}
                  onChange={handleChange}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>

                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="allow-no-clients" mb="0">
                    Allow empty client list?
                  </FormLabel>
                  <Switch
                    isChecked={allowEmptyClientList}
                    onChange={(e) =>
                      setAllowEmptyClientList(() => e.target.checked)
                    }
                    id="allow-no-clients"
                  />
                </FormControl>
                <Text>
                  {allowEmptyClientList ? 'is Allowed' : 'is NOT allowed'}
                </Text>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="indigo" mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
      <Flex
        hidden={!loggedIn}
        borderColor="indigo.800"
        borderStyle="dashed"
        borderWidth="thick"
        py={4}
      >
        <Stack
          isInline
          h="200px"
          w="100%"
          hidden={subscribeStatus === 'SUCCEEDED'}
          bg={subscribeStatus === 'init' ? 'indigo.50' : 'gray.100'}
          justify="center"
          spacing={2}
          align="center"
        >
          {subscribeStatus === 'FAILED' ? (
            <WarningTwoIcon w={8} h={8} color="red.500" />
          ) : (
            // <Icon icon={<MdWarning />} size="48px" color="red.500" aria-label='Warning' />
            <Spinner
              hidden={subscribeStatus === 'init'}
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="indigo.500"
              size="xl"
            />
          )}
          <Text fontSize="3xl" color="white">
            {subscribeStatus}
          </Text>
        </Stack>
        <OutputView
          columns={columns}
          data={data}
          hidden={subscribeStatus !== 'SUCCEEDED'}
          isFilled
          w="100%"
        />
      </Flex>
    </Stack>
  );
};

export default ResultsComponent;
