import { Stack, Text } from '@chakra-ui/react';
import { useDrag } from 'react-dnd';
// import { ACTIONS } from '../utils/actions';
import { FC } from 'react';
import { ItemTypes } from '../utils/items';
import { useQueryBuilder } from '../context/queryBuilderProvider';

const AthenaColumn: FC<{ name: string }> = ({
  name,
  // setDimItems,
  // setMetricItems,
  // findColumn,
  ...props
}) => {
  const { findColumn } = useQueryBuilder();

  const [, drag] = useDrag(() => ({
    type: ItemTypes.CARD,
    column: name,
    datatype: findColumn(name).datatype,
    item: {
      column: name,
      datatype: findColumn(name).datatype,
    },

    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <Stack
      ref={drag}
      m={1}
      key={name}
      isInline
      bg="indigo.900"
      color="white"
      borderColor="white"
      borderWidth="1px"
      flex="0 0 30%"
      boxShadow="lg"
      overflowX="hidden"
      {...props}
    >
      <Text mx={1}>{name}</Text>
    </Stack>
  );
};

export default AthenaColumn;
