import { extendTheme } from '@chakra-ui/react';

// Let's say you want to add custom colors
const customTheme = extendTheme({
  colors: {
    indigo: {
      50: '#cfdbe1',
      100: '#a0b6c3',
      200: '#88a4b5',
      300: '#588097',
      400: '#416d88',
      500: '#11496a',
      600: '#0f425f',
      700: '#0e3a55',
      800: '#0c334a',
      900: '#092535',
    },
    omd_red: {
      50: '#fdeae9',
      100: '#fac1bd',
      200: '#f79892',
      300: '#f36f66',
      400: '#f0463a',
      500: '#ee3124',
      600: '#d62c20',
      700: '#a72219',
      800: '#5f140e',
      900: '#180504',
    },
    phd_purple: {
      50: '#ebe8ee',
      100: '#c2bbcd',
      200: '#9a8eac',
      300: '#72618a',
      400: '#493469',
      500: '#351d58',
      600: '#301a4f',
      700: '#25143e',
      800: '#1b0f2c',
      900: '#0b0612',
    },
    phd_pink: {
      50: '#fbe6ee',
      100: '#f3b3cc',
      200: '#ef99bb',
      300: '#e6679a',
      400: '#de3478',
      500: '#d60156',
      600: '#c1014d',
      700: '#ab0145',
      800: '#800134',
      900: '#560022',
    },
    hs_pink: {
      50: '#ffe6f5',
      100: '#ffcceb',
      200: '#ffb3e0',
      300: '#ff80cc',
      400: '#ff33ad',
      500: '#ff0099',
      600: '#e6008a',
      700: '#b3006b',
      800: '#80004d',
      900: '#66003d',
    },
  },
  fonts: {
    body: 'Verdana, Geneva, sans-serif',
    heading: 'Verdana, Geneva, sans-serif',
    mono: 'Menlo, monospace',
  },
});

export default customTheme;
