import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
  CloseButton,
  AlertStatus,
} from '@chakra-ui/react';
import { FC, MouseEventHandler } from 'react';

const AlertComponent: FC<{
  isOpen: boolean;
  onClose: MouseEventHandler<HTMLButtonElement>;
  status: AlertStatus;
  title: string;
  description: string;
}> = ({ isOpen, onClose, status, title, description, ...props }) => (
  <Alert
    status={status}
    variant="subtle"
    justifyContent="center"
    alignItems="center"
    hidden={!isOpen}
    {...props}
  >
    <AlertIcon boxSize="40px" mr={2} />
    <Stack m={2}>
      <AlertTitle fontSize="lg">{title}</AlertTitle>
      <AlertDescription maxWidth="sm">{description}</AlertDescription>
    </Stack>
    <CloseButton onClick={onClose} />
  </Alert>
);

export default AlertComponent;
