/* eslint-disable react/jsx-filename-extension */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './App.css';
import {
  Button,
  Stack,
  Heading,
  Select,
  IconButton,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { MdHome, MdBrightness6 } from 'react-icons/md';
import Amplify from 'aws-amplify';
import // BrowserRouter as Router,

// Route,
// useLocation,
// useNavigate,
// Link as ReachLink,
'react-router-dom';
// import { Navigate } from 'react-router';
// import the mutation from ';
import { getUnixTime } from 'date-fns';

import HistoryDrawer from './components/HistoryDrawer';
import { useGlobal } from './context/provider';
import ModalCheatSheet from './components/ModalCheatSheet';
import AlertComponent from './components/AlertComponent';

import { useQueryBuilder } from './context/queryBuilderProvider';
import DataExplorerQuery from './components/DataExplorerQuery';
// import AudioVideoReport from './components/tvReporting/AudioVideoReport';
// import PrivateRoute from './components/PrivateRoute';
// import Callback from './components/Callback';
import { useAuth } from './context/authProvider';
// import NoMatch from './components/NoMatch';
import { RGBAToHexA } from './utils/helpers';
import './i18n';
// import QueryBuilderComponent from './components/QueryBuilderComponent';
// import QueryBuilderMeta from './components/QueryBuilderMeta';

const {
  // REACT_APP_CAMPAIGN_MAPPING_BASE_URL,
  REACT_APP_GRAPHQL_ENDPOINT,
  
  REACT_APP_USER_FILES_S3_BUCKET,
  REACT_APP_IDENTITY_POOL_ID,
  REACT_APP_AWS_REGION,
  REACT_APP_USER_POOL_ID,
  REACT_APP_USER_WEB_CLIENT_ID,
  REACT_APP_REDIRECT_SIGN_IN,
  REACT_APP_REDIRECT_SIGN_OUT,
} = process.env;

const amplifyConf = {
  aws_project_region: 'eu-west-1',
  aws_appsync_graphqlEndpoint: REACT_APP_GRAPHQL_ENDPOINT,
  aws_appsync_region: REACT_APP_AWS_REGION,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  // aws_appsync_apiKey: REACT_APP_APPSYNC_API_KEY,
  aws_user_files_s3_bucket: REACT_APP_USER_FILES_S3_BUCKET,
  aws_user_files_s3_bucket_region: REACT_APP_AWS_REGION,
  Auth: {
    identityPoolId: REACT_APP_IDENTITY_POOL_ID,
    region: REACT_APP_AWS_REGION,
    userPoolId: REACT_APP_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_USER_WEB_CLIENT_ID,
    oauth: {
      domain: 'authentication.auth.eu-west-1.amazoncognito.com',
      scope: ['openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: REACT_APP_REDIRECT_SIGN_IN,
      redirectSignOut: REACT_APP_REDIRECT_SIGN_OUT,
      responseType: 'token',
    },
    Storage: {
      AWSS3: {
        bucket: REACT_APP_USER_FILES_S3_BUCKET,
        region: REACT_APP_AWS_REGION,
        identityPoolId: REACT_APP_IDENTITY_POOL_ID,
        userPoolId: REACT_APP_USER_POOL_ID,
      },
    },
  },
};
Amplify.configure(amplifyConf);

// function PrivateRoute({ element, path }) {
//   const { isLoggedIn, login, logout } = useAuth();
//   const ele = isLoggedIn === true ? element : <Navigate to="/callback" />;
//   // return <Route path={path} element={ele} />;
// }

const App = () => {
  // const [searchClients, setSearchClients] = useState('');

  const resetAlert = { message: '', title: '', callSignOut: false };
  const [generalAlert, setGeneralAlert] = useState(resetAlert);
  const [showGeneralAlert, setShowGeneralAlert] = useState(false);
  const timeExpired = useRef(false);
  const [shouldLogOut, setShouldLogOut] = useState(false);

  const {
    userName,
    // loggedIn,
    // signOut,
    countries,
    selectCountry,
    agencies,
    selectAgency,
    allClients,
    accessInfo,
  } = useGlobal();

  const {
    // userSignIn,
    updateQuery,
    selectedAgency,
    selectedCountry,
    UpdateAvailableClients,
  } = useQueryBuilder();

  const { expiration } = accessInfo;

  // const location = useLocation();
  // const navigate = useNavigate();
  const { isLoggedIn, login, logout } = useAuth();
  // const { pathname } = location;

  const signIn = () => {
    // console.log('Hub PrivateRoute Location', location);
    const pathname = 'dataexplorer';
    login(pathname);
  };
  const signOut = () => {
    // console.log('Hub PrivateRoute Location sign out', location);
    logout();
  };

  const alertMessage = useCallback((message, title, callSignOut) => {
    console.log('alertMessage called with', message);
    const qAlert = { message, title, callSignOut };
    setGeneralAlert(() => qAlert);
    setShowGeneralAlert(true);
    setShouldLogOut(callSignOut);
  }, []);

  const alertSignedOut = useCallback(() => {
    const message = 'You are logged out - please sign in again';
    const title = 'Cannot start query';
    const callSignOut = true;
    alertMessage(message, title, callSignOut);
  }, [alertMessage]);

  useEffect(() => {
    if (!timeExpired.current) {
      if (expiration.unix - getUnixTime(Date.now()) < 0) {
        console.log(
          'Every render',
          getUnixTime(Date.now()),
          expiration.unix,
          (expiration.unix - getUnixTime(Date.now())) / 3600,
          expiration.unix - getUnixTime(Date.now()) < 0
        );
        timeExpired.current = true;

        alertSignedOut();
      }
    }
  });

  function selectNewCountry(e) {
    console.log('NewCountry Called....SelectNewCalled', e);

    UpdateAvailableClients(allClients, e, selectedAgency);
    selectCountry(e);
  }
  function selectNewAgency(e) {
    console.log('NewAgency Called....SelectNewCalled', e);

    UpdateAvailableClients(allClients, selectedCountry, e);
    selectAgency(e);
  }

  const closeAlert = (callSignOut) => {
    setGeneralAlert(() => resetAlert);
    console.log('CloseAlert called in callback');
    // generalAlert.current = resetAlert;

    if (callSignOut) {
      console.log('it should sign out');

      signOut();
    }

    setShowGeneralAlert(false);
  };

  // console.log('Hub.. callback URL', REACT_APP_REDIRECT_SIGN_IN);

  const handleClick = (path) => {
    console.log(path);
    // navigate(path, { replace: true });
  };

  const { toggleColorMode } = useColorMode();

  const bg = useColorModeValue('indigo.50', RGBAToHexA(255, 255, 255, 0.08));
  const color = useColorModeValue('gray.800', 'white');

  return (
    // <Router>
    <Stack m={2} spacing={4}>
      <AlertComponent
        isOpen={showGeneralAlert}
        onClose={() => closeAlert(shouldLogOut)}
        title={generalAlert.title}
        description={generalAlert.message}
        status="error"
        m={8}
      />
      <Stack isInline bg="indigo.500" justify="space-around" align="center">
        <IconButton icon={<MdHome />} onClick={() => handleClick('/')} />
        <IconButton icon={<MdBrightness6 />} onClick={toggleColorMode} />

        {/* <Button size="sm" colorScheme="blue" onClick={toggleColorMode}>
          Toggle Mode
        </Button> */}
        <ModalCheatSheet ml={4} />
        <Heading
          as="h1"
          size="2xl"
          // textShadow="1px 1px 4px #092535"
          textShadow="4px 4px 4px rgba(255, 255, 255, 0.3)"
          letterSpacing="widest"
          textAlign="center"
          color="white"
          flex="1"
        >
          data explorer
        </Heading>
        <HistoryDrawer
          m={2}
          flex="0 0 auto"
          align="flex-end"
          updateFunction={updateQuery}
          userName={isLoggedIn ? userName : 'NOTSET'}
        />
        <Stack m={4}>
          <Stack isInline align="center">
            {/* <Badge>{formatDistanceToNow(expiration.timeStamp)}</Badge> */}
            <Select
              variant="solid"
              bg={bg}
              color={color}
              value={selectedCountry}
              onChange={(e) => selectNewCountry(e.target.value)}
            >
              {countries.map((c) => (
                <option key={c.value} value={c.value}>
                  {c.value.toUpperCase()}
                </option>
              ))}
            </Select>
            <Select
              variant="solid"
              bg={bg}
              color={color}
              // colorScheme="indigo"
              value={selectedAgency}
              onChange={(e) => selectNewAgency(e.target.value)}
            >
              {agencies.map((a) => (
                <option key={a.value} value={a.value}>
                  {a.value.toUpperCase()}
                </option>
              ))}
            </Select>
          </Stack>
          {isLoggedIn ? (
            <Button bg={bg} color={color} onClick={signOut}>
              Sign Out
            </Button>
          ) : (
            <Button bg={bg} color={color} onClick={signIn}>
              Sign In
            </Button>
          )}
        </Stack>

        {/* <Badge>{database}</Badge> */}
      </Stack>

      <Stack hidden={isLoggedIn} align="center" justify="center">
        <Heading>Welcome to data explorer</Heading>
        <Heading>Please sign in</Heading>
      </Stack>
      <DataExplorerQuery
        hidden={!isLoggedIn}
        alertSignedOut={alertSignedOut}
        alertMessage={alertMessage}
      />

      {/* <Routes>
        <Route exact path="/callback" render={() => <Callback />} />
        <Route
          // path="/DataExplorer"
          exact
          path="/"
          element={
            <DataExplorerQuery
              alertSignedOut={alertSignedOut}
              alertMessage={alertMessage}
            />
          }
        />

        <Route
          exact
          path="/DataExplorer"
          element={
            <DataExplorerQuery
              alertSignedOut={alertSignedOut}
              alertMessage={alertMessage}
            />
          }
        />

        <Route path="*" element={<NoMatch />} />
      </Routes> */}
    </Stack>
    // </Router>
  );
};

export default App;
