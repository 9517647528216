/* eslint-disable react/prop-types */

import { Auth, Hub } from 'aws-amplify';
import { getUnixTime } from 'date-fns';
import { createContext, useContext, useEffect, useState } from 'react';
import { useQueryBuilder } from './queryBuilderProvider';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

/* 
The Auth service that you just created contains functions to deal with different 
steps of the sign in/sign up process. 


The following list briefly summarizes these functions and what they do:

getProfile: This function returns the profile of the logged-in user.
handleAuthentication: This function looks for the result of the authentication process in the URL hash. 
                      Then, the function processes the result with the parseHash method from auth0-js.
isAuthenticated: This function checks whether the expiry time for the user's ID token has passed.
login: This function initiates the login process, redirecting users to the login page.
logout: This function removes the user's tokens and expiry time.
setSession: This function sets the user's ID token, profile, and expiry time.
 */

export const AuthProvider = ({ children }) => {
  const { userSignIn } = useQueryBuilder();
  const initExpTime = 1893456000;
  const initUser = {
    name: 'notset',
    accessToken: 'notset',
    authTime: initExpTime,
    expirationTime: initExpTime,
  };

  const [profile, setProfile] = useState(initUser);
  const [userData, setUserData] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginPath, setLoginPath] = useState('notset');

  const getProfile = () => profile;

  const handleAuthentication = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log(user);
        setUserData(() => user);
      })
      .catch(() => console.log('Not signed in'));
  };

  const login = (l) => {
    console.log('Auth provider called login', l);

    Auth.federatedSignIn({ customState: l }).then((d) =>
      console.log('Auth provider called login after sign', d)
    );
  };

  const isAuthenticated = profile.expirationTime - getUnixTime(Date.now()) < 0; // && isLoggedIn;

  useEffect(() => {
    if (!userData || !userData.signInUserSession) {
      setProfile(() => initUser);
      setIsLoggedIn(false);
      return;
    }
    const { payload } = userData.signInUserSession.idToken;
    const name = `${payload.name} ${payload.family_name}`;
    const accessToken = userData.signInUserSession.accessToken.jwtToken;
    console.log('Auth userSign In userData', userData);
    const { auth_time: authTime, exp: expirationTime } =
      userData.signInUserSession.accessToken.payload;
    const newUser = { name, accessToken, authTime, expirationTime };
    setProfile(() => newUser);
    setIsLoggedIn(true);

    userSignIn(newUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const logout = () => {
    setProfile(initUser);
    setIsLoggedIn(false);
  };

  useEffect(() => {
    Hub.listen(
      'auth',
      ({ payload: { event, data: payloadData, ...rem }, ...rest }) => {
        console.log('Auth listen', event, payloadData, rest, rem);
        switch (event) {
          case 'signIn':
            console.log('Auth call sign in');
            break;
          case 'cognitoHostedUI':
            console.log('Auth call sign in CognitoHosted');
            handleAuthentication();

            break;
          case 'signOut':
            console.log('Auth Sign out', payloadData);
            logout();

            break;
          case 'oAuthSignOut':
            console.log('Auth oAuthSignOut', payloadData);
            logout();

            break;

          case 'signIn_failure':
          case 'parsingCallbackUrl':
            console.log('Auth   Sign in parsingCallbackUrl', payloadData);

            break;
          case 'cognitoHostedUI_failure':
            logout();
            // setLoggedIn(false);
            console.log('Auth Sign in failure', payloadData);
            // eslint-disable-next-line no-alert
            alert(`Auth Sign in failure: ${payloadData}`);
            break;
          case 'customOAuthState':
            console.log(
              'Auth Sign in customOAuthState',
              event,
              payloadData,
              rest,
              rem
            );
            setLoginPath(() => payloadData);
            break;

          default:
            // eslint-disable-next-line no-alert
            alert(`Auth Unknown event failure: ${event}`);
            console.log('Auth Unknown event failure: ', event);
        }
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        getProfile,
        handleAuthentication,
        isAuthenticated,
        isLoggedIn,
        login,
        logout,
        profile,

        loginPath,
        setLoginPath,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// export default useGlobal;
