/* eslint-disable no-shadow */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useFlexLayout, useResizeColumns, useTable } from 'react-table';
import PropTypes from 'prop-types';
import { Box, Flex, Text, useColorMode } from '@chakra-ui/react';

const getStyles = (props, align = 'left') => [
  props,
  {
    style: {
      justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      alignItems: 'flex-start',
      display: 'flex',
    },
  },
];

const headerProps = (props, { column }) => getStyles(props, column.align);

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

const resizerProps = {
  position: 'absolute',
  top: 0,
  right: 0,
  width: '10px',
  height: '100%',
  zIndex: 1,
  style: { touchAction: 'none' },
};

const Table = ({
  columns,
  data,
  emptyMessage = 'No records',
  onRowClick,
  // eslint-disable-next-line react/prop-types
  children,
  ...rest
}) => {
  const { colorMode } = useColorMode();
  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useResizeColumns,
    useFlexLayout,
    (hooks) => {
      // eslint-disable-next-line no-shadow
      hooks.allColumns.push((p) => p);
    }
  );

  return (
    <Box {...getTableProps()} flex={1} fontSize="sm" mt={1} {...rest}>
      <Box overflowY="auto" overflowX="hidden">
        {headerGroups.map((headerGroup, i) => (
          <Flex
            flex={1}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            {...headerGroup.getHeaderGroupProps({})}
            borderBottom="1px solid"
            borderColor="gray.300"
          >
            {headerGroup.headers.map((column, j, cols) => (
              <Text
                as="div"
                // eslint-disable-next-line react/no-array-index-key
                key={j}
                {...column.getHeaderProps(headerProps)}
                textAlign="left"
                minHeight="24px"
              >
                {column.render('Header')}
                {column.canResize && j < cols.length - 1 && (
                  <Box {...resizerProps} {...column.getResizerProps()} />
                )}
              </Text>
            ))}
          </Flex>
        ))}
      </Box>
      <Box overflowY="scroll" overflowX="hidden">
        {rows.length ? (
          rows.map((row) => {
            prepareRow(row);
            return (
              <Box
                display="flex"
                flex={1}
                key={row.id}
                data-rowindex={row.index}
                {...row.getRowProps()}
                onClick={onRowClick}
                cursor={onRowClick ? 'pointer' : undefined}
                _hover={{ bg: colorMode === 'dark' ? 'gray.700' : 'gray.100' }}
                minHeight="24px"
                lineHeight="24px"
              >
                {row.cells.map((cell) => (
                  <Text
                    as="div"
                    key={row.id + '_' + cell.index}
                    wordBreak="break-all"
                    {...cell.getCellProps(cellProps)}
                  >
                    {cell.render('Cell')}
                  </Text>
                ))}
              </Box>
            );
          })
        ) : (
          <Text as="div" p="4px 24px" fontSize="md">
            {emptyMessage}
          </Text>
        )}
      </Box>
      {children}
    </Box>
  );
};

Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object).isRequired,

  onRowClick: PropTypes.func.isRequired,
  emptyMessage: PropTypes.string.isRequired,
  // getColumn: PropTypes.func.isRequired,
  // selectorHeight: PropTypes.number.isRequired,
};

export default Table;
