import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Badge, Box, Flex, Input, LightMode, Stack } from '@chakra-ui/react';
import { useQueryBuilder } from '../context/queryBuilderProvider';

const ClientSelector = ({ ...props }) => {
  const { selectedClients, toggleClient, clients } = useQueryBuilder();

  const [showList, setShowList] = useState(false);
  const [searchClients, setSearchClients] = useState('');
  const [filteredClients, setFilteredClients] = useState([]);

  const onChangeSearchList = (val) => {
    // console.log('On Change searchList called.... val', val);
    if (val) {
      setShowList(true);
    } else {
      setShowList(false);
    }
    setSearchClients(val);
  };

  useEffect(() => {
    if (searchClients) {
      const newFiltered = clients.filter((client) =>
        client.client_name.toLowerCase().includes(searchClients.toLowerCase())
      );

      setFilteredClients(newFiltered);
    } else {
      setFilteredClients([]);
    }
  }, [clients, searchClients]);
  return (
    <Stack {...props} flex="1" isInline>
      <Stack flex="2" overflowY="auto">
        <Flex wrap="wrap" align="space-between" justify="flex-start">
          {selectedClients.map(
            ({
              client_name: clientName,
              selected,
              client_code: clientCode,
              country,
            }) => (
              <LightMode key={clientCode + '-' + country}>
                <Badge
                  m="3px"
                  overflowX="hidden"
                  flex="0 0 30%"
                  onClick={() => toggleClient(clientCode, country)}
                  colorScheme={selected ? 'green' : 'red'}
                >
                  {clientName}
                </Badge>
              </LightMode>
            )
          )}
        </Flex>
      </Stack>
      <Box flex="1">
        <Input
          placeholder="Search"
          onChange={(e) => onChangeSearchList(e.target.value)}
          variant="filled"
        />
        <Box mt={4} hidden={!showList}>
          <Stack maxH="150px" overflowY="auto">
            {filteredClients.map(
              ({
                client_name: clientName,
                selected,
                client_code: clientCode,
                country,
              }) => (
                <LightMode key={clientCode + '-' + country}>
                  <Badge
                    onClick={() => toggleClient(clientCode, country)}
                    // key={clientCode + '-' + country}
                    colorScheme={selected ? 'green' : 'red'}
                  >
                    {clientName}
                  </Badge>
                </LightMode>
              )
            )}
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};

ClientSelector.propTypes = {};

export default ClientSelector;
