import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
} from '@chakra-ui/react';

import { ReactComponent as CheatSheet } from '../images/cheatsheet.svg';

const ModalCheatSheet = ({ ...rest }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box {...rest}>
      <Button onClick={onOpen}>Cheat sheet</Button>
      <Modal
        size="full"
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>CheatSheet</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CheatSheet />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

// ModalCheatSheet.propTypes = {};

export default ModalCheatSheet;
