const { Storage } = require('aws-amplify');

// eslint-disable-next-line import/prefer-default-export
export async function getDescribeTableLink(source) {
  const link = await Storage.get(source, {
    level: 'protected',
    identityId: 'describeTable',
  });

  return link;
}
