import { Dispatch, FC, useState } from 'react';
import {
  Button,
  Select,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import { ACTIONS } from '../utils/actions';
import { useQueryBuilder } from '../context/queryBuilderProvider';

const inValidPrettyNames = [
  'ALTER',
  'AND',
  'AS',
  'BETWEEN',
  'BY',
  'CASE',
  'CAST',
  'CONSTRAINT',
  'CREATE',
  'CROSS',
  'CUBE',
  'CURRENT_DATE',
  'CURRENT_TIME',
  'CURRENT_TIMESTAMP',
  'CURRENT_USER',
  'DEALLOCATE',
  'DELETE',
  'DESCRIBE',
  'DISTINCT',
  'DROP',
  'ELSE',
  'END',
  'ESCAPE',
  'EXCEPT',
  'EXECUTE',
  'EXISTS',
  'EXTRACT',
  'FALSE',
  'FOR',
  'FROM',
  'FULL',
  'GROUP',
  'GROUPING',
  'HAVING',
  'IN',
  'INNER',
  'INSERT',
  'INTERSECT',
  'INTO',
  'IS',
  'JOIN',
  'LEFT',
  'LIKE',
  'LOCALTIME',
  'LOCALTIMESTAMP',
  'NATURAL',
  'NORMALIZE',
  'NOT',
  'NULL',
  'ON',
  'OR',
  'ORDER',
  'OUTER',
  'PREPARE',
  'RECURSIVE',
  'RIGHT',
  'ROLLUP',
  'SELECT',
  'TABLE',
  'THEN',
  'TRUE',
  'UESCAPE',
  'UNION',
  'UNNEST',
  'USING',
  'VALUES',
  'WHEN',
  'WHERE',
  'WITH',
];
const ModalMetric: FC<{
  id: string;
  modalColumn: string;
  modalAggMethod: string;
  isOpen: boolean;
  onClose: () => void;
  setModalAggMethod: Dispatch<React.SetStateAction<string>>;
  datatype: string;
}> = ({
  id,
  modalColumn,
  modalAggMethod,
  isOpen,
  onClose,
  // setMetricItems,
  setModalAggMethod,
  datatype,
  ...props
}) => {
  const [validPrettyName, setValidPrettyName] = useState(true);

  const { updateMetric } = useQueryBuilder();

  function updatePrettyName(val: string, updateId: string) {
    const isValid =
      !inValidPrettyNames.includes(val.toUpperCase()) && val.length > 0;
    setValidPrettyName(isValid);

    if (isValid) {
      updateMetric(ACTIONS.UPDATE.METRIC.PRETTY_NAME, updateId, val);
    }
  }

  function updateAggMethod(val: string, updateId: string) {
    console.log(val, updateId, setModalAggMethod);

    setModalAggMethod(() => val);
    updateMetric(ACTIONS.UPDATE.METRIC.AGGREGATION_METHOD, updateId, val);
  }

  return (
    <Modal
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={validPrettyName}
      {...props}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Change output name:
          {modalColumn}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text as="i" fontSize="xs" hidden={validPrettyName}>
            please use valid name
          </Text>
          <InputGroup>
            <Input
              pr="15rem"
              size="lg"
              color="indigo.500"
              placeholder={modalColumn}
              variant="flushed"
              p={1}
              isInvalid={!validPrettyName}
              errorBorderColor="crimson"
              onChange={(e) => updatePrettyName(e.target.value, id)}
            />

            <InputRightElement
              w="auto"
              // w="10rem"
            >
              <Select
                size="lg"
                color="indigo.500"
                // p={2}
                variant="outline"
                value={modalAggMethod}
                onChange={(e) => updateAggMethod(e.target.value, id)}
              >
                {datatype !== 'DATE' && <option value="SUM">SUM</option>}
                {datatype !== 'DATE' && <option value="AVG">AVG</option>}
                <option value="MIN">MIN</option>
                <option value="MAX">MAX</option>
              </Select>
            </InputRightElement>
          </InputGroup>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={onClose}
            isDisabled={!validPrettyName}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalMetric;
