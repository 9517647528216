import { FC } from 'react';
import * as PropTypes from 'prop-types';
import { Box, Heading, useColorModeValue } from '@chakra-ui/react';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from '../utils/items';
import { useQueryBuilder } from '../context/queryBuilderProvider';
import { IDragItem } from '../utils/commonInterfaces';

// import { ACTIONS } from '../utils/actions';

interface IDimItem {
  id: string;
  index: number;
  dim: string;
  // name: string;
}

const DimensionItem: FC<IDimItem> = ({ id, index, dim, ...props }) => {
  const { moveCard, findIndexById } = useQueryBuilder();

  const originalIndex = findIndexById(ItemTypes.DIM, id);

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.DIM,
    item: { type: ItemTypes.DIM, id, originalIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex: originalIdx } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        console.log('dimensionItem did not drop', droppedId, originalIdx);
        moveCard(droppedId, originalIdx, ItemTypes.DIM);
      }
    },
  });
  const [, drop] = useDrop<IDragItem, IDragItem, { isDragging: boolean }>({
    accept: ItemTypes.DIM,
    canDrop: () => false,
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        const overIndex = findIndexById(ItemTypes.DIM, id);
        // console.log('hover',draggedId, overIndex)
        moveCard(draggedId, overIndex, ItemTypes.DIM);
        // moveDimensions(draggedId, overIndex)
      }
    },
  });
  const opacity = isDragging ? 0 : 1;

  const bg = useColorModeValue('indigo.100', 'indigo.800');
  const color = useColorModeValue('gray.800', 'indigo.100');
  const borderColor = useColorModeValue('gray.100', 'gray.800');

  return (
    <Box
      ref={(node) => drag(drop(node))}
      border="1px"
      px={1}
      py={2}
      rounded="md"
      borderColor={borderColor}
      shadow="lg"
      m={1}
      backgroundColor={bg}
      color={color}
      cursor="move"
      opacity={opacity}
      {...props}
    >
      {/* <Heading size="sm">{dim+ '('+ index +')' }</Heading> */}
      <Heading size="sm">{dim}</Heading>
    </Box>
  );
};

DimensionItem.propTypes = {
  id: PropTypes.string.isRequired,
  dim: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,

  // findDim: PropTypes.func.isRequired,
};

export default DimensionItem;
