/* eslint-disable react/jsx-one-expression-per-line */

import { Stack } from '@chakra-ui/react';
import { FC } from 'react';
import QueryBuilderMeta from './QueryBuilderMeta';
import QueryBuilderComponent from './QueryBuilderComponent';
import ResultsComponent from './ResultsComponent';

const DataExplorerQuery: FC<{
  alertSignedOut: () => void;
  alertMessage: () => void;
}> = ({ alertSignedOut, alertMessage, ...props }) => {
  const selectorHeight = 318;

  return (
    <Stack {...props}>
      <QueryBuilderMeta />
      <QueryBuilderComponent selectorHeight={selectorHeight} />
      <ResultsComponent
        alertSignedOut={alertSignedOut}
        alertMessage={alertMessage}
      />
    </Stack>
  );
};

export default DataExplorerQuery;
