import { FC } from 'react';
import {
  Stack,
  Text,
  Select,
  Input,
  useColorModeValue,
} from '@chakra-ui/react';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from '../utils/items';
import { IFilterValue, useQueryBuilder } from '../context/queryBuilderProvider';
import { IDragItem } from '../utils/commonInterfaces';

interface IFilterItemScope {
  id: string;
  column: string;
  filter: IFilterValue;
  index: number;
}

const FilterItem: FC<IFilterItemScope> = ({
  id,
  column,
  filter,
  // updateFilter,
  index,
  // findFilter,
  // moveCard,
  ...props
}) => {
  const { moveCard, updateFilter, findIndexById } = useQueryBuilder();
  const originalIndex = findIndexById(ItemTypes.FILTER, id);

  const [{ isDragging }, drag] = useDrag<
    IDragItem,
    IDragItem,
    { isDragging: boolean }
  >(() => ({
    type: ItemTypes.FILTER,
    item: { type: ItemTypes.FILTER, id, originalIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (_dropResult, monitor) => {
      const { id: droppedId, originalIndex: orig } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        console.log('did not drop', droppedId, orig);
        moveCard(droppedId, orig, ItemTypes.FILTER);
      }
    },
  }));
  const [, drop] = useDrop<IDragItem, IDragItem, { isDragging: boolean }>({
    accept: ItemTypes.FILTER,
    canDrop: () => false,
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        const overIndex = findIndexById(ItemTypes.FILTER, id);
        //   console.log('hover',draggedId, overIndex)
        moveCard(draggedId, overIndex, ItemTypes.FILTER);
      }
    },
  });
  const opacity = isDragging ? 0 : 1;
  const bg = useColorModeValue('indigo.700', 'indigo.500');
  const color = useColorModeValue('gray.100', 'gray.900');
  // const color = useColorModeValue('gray.100', 'indigo.800');

  return (
    <Stack
      ref={(node) => drag(drop(node))}
      border="1px"
      px={1}
      py={2}
      m={1}
      rounded="md"
      // backgroundColor="indigo.700"
      // bg={bg}
      cursor="move"
      align="center"
      opacity={opacity}
      key={id}
      isInline
      {...props}
    >
      {/* <Text flex="0" >{column+'('+index+")"}</Text> */}
      <Text flex="0">{column}</Text>
      <Select
        flex="0 0 70px"
        size="sm"
        variant="outline"
        // bg={bg}
        // color={color}
        // bg="indigo.50"
        // color="indigo.500"
        value={filter.filtertype}
        onChange={(e) => updateFilter(e.target.value, id, 'filtertype')}
      >
        <option key="1" value="EQUAL">
          =
        </option>
        <option key="2" value="LT">
          {'<'}
        </option>
        <option key="3" value="LTE">
          {'<='}
        </option>
        <option key="4" value="GT">
          {'>'}
        </option>
        <option key="5" value="GTE">
          {'>='}
        </option>
      </Select>
      <Input
        flex="1"
        size="sm"
        id={column}
        color={color}
        variant="outline"
        bg={bg}
        placeholder={filter.value}
        onChange={(e) => {
          const targetEvent = e.target;
          if (!targetEvent) {
            console.error(`No targetEvent received`, e);
            throw new Error(`No targetEvent received`);
          }
          const updatedFilter = targetEvent.value;
          if (!updatedFilter) {
            console.error(`No targetEvent Value received`, targetEvent);
            throw new Error(`No targetEvent received`);
          }
          updateFilter(updatedFilter, id, 'value');
        }}
      />
    </Stack>
  );
};

export default FilterItem;
