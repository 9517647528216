import { FC } from 'react';

import { Stack } from '@chakra-ui/react';
import ColumnsBox from './ColumnsBox';
import MetricsBox from './MetricsBox';
import DimensionBox from './DimensionBox';
import FilterBox from './FilterBox';
import { useGlobal } from '../context/provider';
// import { useQueryBuilder } from '../context/queryBuilderProvider';

const QueryBuilderComponent: FC<{ selectorHeight: number }> = ({
  selectorHeight,
  ...props
}) => {
  const { loggedIn } = useGlobal();

  //   const {checkedItems,dimItems, filterItems} useQueryBuilder()

  return (
    <Stack hidden={!loggedIn} isInline spacing={4} {...props}>
      <ColumnsBox flex="3" selectorHeight={selectorHeight} />
      <MetricsBox flex="2" selectorHeight={selectorHeight} />
      <DimensionBox flex="2" selectorHeight={selectorHeight} />
      <FilterBox flex="2" selectorHeight={selectorHeight} />
    </Stack>
  );
};

export default QueryBuilderComponent;
