/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const startQuery = /* GraphQL */ `
  mutation StartQuery($query: queryInput!) {
    startQuery(query: $query) {
      id
      status
      submitted
      outputlocation
      bytes_scanned
      user_name
      source
      bucket
      query
      error
      message
    }
  }
`;
export const setStatus = /* GraphQL */ `
  mutation SetStatus($id: String!, $status: statusEnum) {
    setStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;
export const setQueryInfo = /* GraphQL */ `
  mutation SetQueryInfo(
    $id: String!
    $outputlocation: String
    $bytes_scanned: String
  ) {
    setQueryInfo(
      id: $id
      outputlocation: $outputlocation
      bytes_scanned: $bytes_scanned
    ) {
      id
      status
      submitted
      outputlocation
      bytes_scanned
      user_name
      source
      bucket
      query
      error
      message
    }
  }
`;
export const addHistory = /* GraphQL */ `
  mutation AddHistory(
    $user_name: String!
    $id: String
    $source: String
    $bucket: String
    $query: String
  ) {
    addHistory(
      user_name: $user_name
      id: $id
      source: $source
      bucket: $bucket
      query: $query
    ) {
      id
      status
      submitted
      outputlocation
      bytes_scanned
      user_name
      source
      bucket
      query
      error
      message
    }
  }
`;
