export const mcdColumns = [
  {
    Header: 'client_name',
    accessor: 'client_name',
    isDim: true,
  },
  {
    Header: 'yw',
    accessor: 'yw',
    isDim: true,
  },
  {
    Header: 'TotalImpressions',
    accessor: 'TotalImpressions',
    isDim: false,
  },
];

export const mcdData = [
  { client_name: 'McDonalds', yw: '202001', TotalImpressions: '1' },
  { client_name: 'McDonalds', yw: '202002', TotalImpressions: '2' },
  { client_name: 'McDonalds', yw: '202003', TotalImpressions: '3' },
  { client_name: 'McDonalds', yw: '202004', TotalImpressions: '4' },
];

export const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    isDim: true,
  },
  {
    Header: 'Age',
    accessor: 'age',
  },
  {
    Header: 'Visits',
    accessor: 'visits',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Profile Progress',
    accessor: 'progress',
  },
];

// export const data = [
//   {
//     name: "John",
//     age: 30,
//     visits: 10,
//     status: "Active",
//     progress: "50%",
//   },
//   {
//     name: "Ellen",
//     age: 28,
//     visits: 60,
//     status: "Active",
//     progress: "100%",
//   },
//   {
//     name: "Ellen",
//     age: 28,
//     visits: 60,
//     status: "Active",
//     progress: "100%",
//   },
//   {
//     name: "Ellen",
//     age: 28,
//     visits: 60,
//     status: "Active",
//     progress: "100%",
//   },
//   {
//     name: "Ellen",
//     age: 28,
//     visits: 60,
//     status: "Active",
//     progress: "100%",
//   },
//   {
//     name: "Ellen",
//     age: 28,
//     visits: 60,
//     status: "Active",
//     progress: "100%",
//   },
//   {
//     name: "Ellen",
//     age: 28,
//     visits: 60,
//     status: "Active",
//     progress: "100%",
//   },
//   {
//     name: "Ellen",
//     age: 28,
//     visits: 60,
//     status: "Active",
//     progress: "100%",
//   },
// ];

export const describeTableData = [
  { column: 'lineitem', dataType: 'string' },
  { column: 'buytype', dataType: 'string' },
  { column: 'media', dataType: 'string' },
  { column: 'mediasection', dataType: 'string' },
  { column: 'lineitemid', dataType: 'bigint' },
  { column: 'adf_campaign_name', dataType: 'string' },
  { column: 'bannerid', dataType: 'bigint' },
  { column: 'bannerattribute1', dataType: 'string' },
  { column: 'bannerattribute2', dataType: 'string' },
  { column: 'bannersize', dataType: 'string' },
  { column: 'adcreativetype', dataType: 'string' },
  { column: 'bannerformat', dataType: 'string' },
  { column: 'bannertype', dataType: 'string' },
  { column: 'banner', dataType: 'string' },
  { column: 'banneradmessage', dataType: 'string' },
  { column: 'campaignid', dataType: 'bigint' },
  { column: 'client_code', dataType: 'string' },
  { column: 'country', dataType: 'string' },
  { column: 'campaign_name', dataType: 'string' },
  { column: 'devicetype', dataType: 'string' },
  { column: 'cost', dataType: 'double' },
  { column: 'sales', dataType: 'double' },
  { column: 'impressions', dataType: 'int' },
  { column: 'clicks', dataType: 'int' },
  { column: 'date', dataType: 'date' },
  { column: 'year', dataType: 'bigint' },
  { column: 'month', dataType: 'bigint' },
  { column: 'day', dataType: 'bigint' },
  { column: 'viewimpressions', dataType: 'int' },
  { column: 'viewimpressionspercent', dataType: 'double' },
  { column: 'uniqueclickspercent', dataType: 'double' },
  { column: 'uniqueimpressionspercent', dataType: 'double' },
  { column: 'avgviewabilitytime', dataType: 'double' },
  { column: 'avgfrequency', dataType: 'double' },
  { column: 'agency', dataType: 'string' },
  { column: 'client_name', dataType: 'string' },
  { column: 'parent_code', dataType: 'string' },
  { column: 'parent_country', dataType: 'string' },
  { column: 'yw', dataType: 'bigint' },
];
