export function move(arr, oldIndex, newIndex) {
  let oldIdx = oldIndex;
  let newIdx = newIndex;
  while (oldIdx < 0) {
    oldIdx += arr.length;
  }
  while (newIdx < 0) {
    newIdx += arr.length;
  }
  if (newIdx >= arr.length) {
    let k = newIdx - arr.length;

    // while (i--) { code(); } is shorthand for saying while (i > 0) { code(); i--; }

    while (k > 1) {
      arr.push(undefined);
      k -= 1;
    }

    // while (k-- + 1) {
    //   arr.push(undefined);
    // }
  }
  arr.splice(newIdx, 0, arr.splice(oldIdx, 1)[0]);
  return arr;
}

export default move;
