/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getQuery = /* GraphQL */ `
  query GetQuery($id: String) {
    getQuery(id: $id) {
      id
      status
      submitted
      outputlocation
      bytes_scanned
      user_name
      source
      bucket
      query
      error
      message
    }
  }
`;
export const getHistoryList = /* GraphQL */ `
  query GetHistoryList($user_name: String, $limit: Int, $nextToken: String) {
    getHistoryList(
      user_name: $user_name
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        submitted
        outputlocation
        bytes_scanned
        user_name
        source
        bucket
        query
        error
        message
      }
      nextToken
    }
  }
`;
export const getEnrichedQuery = /* GraphQL */ `
  query GetEnrichedQuery($PK: String) {
    getEnrichedQuery(PK: $PK) {
      PK
      SK
    }
  }
`;
