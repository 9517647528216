import * as PropTypes from 'prop-types';
import { Stack, Heading } from '@chakra-ui/react';

import { v4 as uuidv4 } from 'uuid';
import { FC } from 'react';
import { Identifier } from 'dnd-core';
import { useDrop } from 'react-dnd';
// import ModalMetric from './ModalMetric';

import { ItemTypes } from '../utils/items';
import MetricsItem from './MetricsItem';
import AlertComponent from './AlertComponent';
import { useQueryBuilder } from '../context/queryBuilderProvider';
import { IItem } from '../utils/commonInterfaces';

const MetricsBox: FC<{ selectorHeight: number; flex: string }> = ({
  selectorHeight,
  ...props
}) => {
  // function MetricsBox({ selectorHeight, ...props }) {
  // const [showAlert, setShowAlert] = useState(false);
  // const [alertDescription, setAlertDescription] = useState('');
  // const [alertTitle, setAlertTitle] = useState('');

  const { addMetric, queryAlert, resetQueryAlert, metricItems, checkedItems } =
    useQueryBuilder();

  function addNewMetric(item: IItem, itemType: Identifier) {
    console.log(itemType);
    if (itemType === ItemTypes.CARD) {
      console.log(item);
      addMetric(item, uuidv4());
    }
  }
  const [{ isOver }, drop] = useDrop<IItem, void, { isOver: boolean }>(
    () => ({
      accept: [ItemTypes.CARD, ItemTypes.METRIC],
      drop: (item, monitor) => {
        const itemType = monitor.getItemType();
        if (!itemType) {
          console.error(`No ItemType received`);

          throw new Error(`No ItemType received`);
        }
        console.log('AddMetric (metrics_box)', checkedItems);

        return addNewMetric(item, itemType);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [checkedItems]
  );

  const onClose = () => resetQueryAlert();

  return (
    <Stack
      shadow="md"
      borderWidth="1px"
      rounded="md"
      overflowY="auto"
      bg={isOver ? 'indigo.900' : 'indigo.100'}
      h={selectorHeight}
      ref={drop}
      {...props}
    >
      <AlertComponent
        isOpen={queryAlert.showAlert}
        onClose={onClose}
        title={queryAlert.alertTitle}
        description={queryAlert.alertDescription}
        status="error"
      />
      <Heading size="sm" color="indigo.900">
        Metrics
      </Heading>
      <Stack spacing={1} pt={1} px={2}>
        {metricItems.map(
          ({ column, aggregationMethod, name, id, datatype }, index) => (
            <MetricsItem
              id={id}
              index={index}
              key={id}
              column={column}
              aggregationMethod={aggregationMethod}
              name={name}
              datatype={datatype}
            />
          )
        )}
      </Stack>
      {/*   <Stack>
        {metricItems.map(({ datatype, name, aggregationMethod }, index) => (
          <Text key={name}>
            {datatype} {name} {index} {aggregationMethod}
          </Text>
        ))}
      </Stack> */}
    </Stack>
  );
};

MetricsBox.propTypes = {
  // items: PropTypes.arrayOf(PropTypes.object).isRequired,
  // setItems: PropTypes.func.isRequired,
  // getColumn: PropTypes.func.isRequired,
  selectorHeight: PropTypes.number.isRequired,
};

export default MetricsBox;
