import { FC, memo } from 'react';

import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
// eslint-disable-next-line import/no-named-as-default
import Table from './Table';
import BarChartComponent from './BarChartComponent';
import { IChartColumns, IDataColumns } from '../utils/commonInterfaces';

const TempOutputView: FC<{
  columns: IChartColumns[];
  data: IDataColumns[];
  hidden: boolean;
  isFilled: boolean;
  w: string;
}> = ({ columns, data, ...props }) => {
  console.log('OutputView columns', columns);
  console.log('OutputView  data', data);
  return (
    <Tabs {...props}>
      <TabList>
        <Tab>Table</Tab>
        <Tab>BarChart</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <Table
            p={4}
            columns={columns}
            data={data}
            onRowClick={(e: any) => console.log(e)}
            // eslint-disable-next-line react/no-children-prop
            children={undefined} //
          />
        </TabPanel>
        <TabPanel>
          <BarChartComponent data={data} columns={columns} chartHeight={300} />
        </TabPanel>
        {/* <TabPanel>
          <p>three!</p>
        </TabPanel> */}
      </TabPanels>
    </Tabs>
  );
};

const OutputView = memo(TempOutputView);

export default OutputView;
