import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import { toPng } from 'html-to-image';

// import { getPngData } from 'recharts-to-png';
// import { saveAs } from 'file-saver';
import { Button, Center, Stack, useTheme } from '@chakra-ui/react';
import { IChartColumns, IDataColumns } from '../utils/commonInterfaces';

// async function getPngData(instance: ReactInstance) {
//   const element: JSX.Element = findDOMNode(instance);
//   if (!element) {
//     throw new Error('Dom node not found');
//   }
//   // console.log('1', element);
//   const pngData = await html2canvas(element).then((c) =>
//     c.toDataURL('image/png', 1.0)
//   );
//   // console.log('3', pngData);
//   return pngData;
// }

// eslint-disable-next-line react/prop-types
const TempBarChartComponent: FC<{
  data: IDataColumns[];
  columns: IChartColumns[];
  chartHeight: number;
}> = ({ data, columns, chartHeight = 300 }) => {
  // console.log('renderedBarChart... data', data);
  // console.log('renderedBarChart... columns', columns);
  const ref = useRef<HTMLDivElement>(null);

  const { colors } = useTheme();
  const [colorList, setColorList] = useState<string[]>([]);
  // const [chart, setChart] = useState();

  const onButtonClick = useCallback(() => {
    console.log('On Button Click', data, columns);

    if (ref.current === null) {
      return;
    }

    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'my-image-name.png';
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref, data, columns]);

  useEffect(() => {
    function GetColorList() {
      const { indigo } = colors;
      const cList = Object.entries<string>(indigo).map<string>(([, v]) => v);
      return cList;
    }
    setColorList(() => GetColorList());
  }, [colors]);

  const getChartData = () => {
    const foundSeriesDims = columns.find((c) => c.isDim);
    if (!foundSeriesDims) {
      console.log('No dimensions found', columns);

      throw new Error('No dimensions found');
    }
    const { Header: nameDim } = foundSeriesDims;
    const foundSeriesMetrics = columns.find((c) => !c.isDim);
    if (!foundSeriesMetrics) {
      console.log('No metrics found', columns);

      throw new Error('No metrics found');
    }
    const { Header: metricDim } = foundSeriesMetrics;
    const seriesDims = columns
      .filter((c) => c.isDim && c.Header !== nameDim)
      .map((c) => c.Header);
    const seriesDim = seriesDims.length === 0 ? nameDim : seriesDims[0];

    interface IChartDictionary {
      [key: string]: {
        [key: string]: number;
      };
    }

    const chartDict: IChartDictionary = {};
    const dataKeys = new Set<string>();

    data.map((d) => {
      const categoryName = d[nameDim];

      const numberValue = Number(d[metricDim]);

      if (!Number.isNaN(numberValue)) {
        if (!(categoryName in chartDict)) {
          chartDict[categoryName] = {};
        }

        const obj = chartDict[categoryName];
        const headerValue = d[seriesDim];
        dataKeys.add(headerValue);
        obj[headerValue] = numberValue;
      }
      return d;
    });

    const chartData = Object.entries(chartDict).map(([k, v]) => {
      const val = { name: k, ...v };

      return val;
    });

    return { chartData, metricDim, nameDim, dataKeys: Array.from(dataKeys) };
  };

  const { chartData, metricDim, dataKeys } = getChartData(); // columns ? getChartData() : notFound;

  function createLabel(title: string) {
    return { value: title, angle: -90, position: 'insideLeft' };
  }

  return (
    <Stack>
      <Button onClick={onButtonClick}>Download Chart</Button>

      <Center height={chartHeight} width="100%">
        <ResponsiveContainer>
          <div ref={ref}>
            <BarChart
              className="htmlToImage"
              width={1000}
              height={300}
              data={chartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis width={100} label={createLabel(metricDim)} />
              <Tooltip />
              <Legend />
              {/* <Bar type="monotone" dataKey="pv" fill="#8884d8" />
            <Bar type="monotone" dataKey="uv" fill="#82ca9d" /> */}
              {dataKeys.map((d, index) => (
                <Bar key={d} dataKey={d} fill={colorList[index]} />
              ))}
            </BarChart>
          </div>
        </ResponsiveContainer>
      </Center>
    </Stack>
  );
};

const BarChartComponent = memo(TempBarChartComponent);
// const BarChartComponent = TempBarChartComponent;

export default BarChartComponent;
